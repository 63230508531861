import { useNavigate } from "react-router-dom"
import "./PartTwoFeedback.css"

import Data from "../PartTwoSim/Data"

export default function PartTwoFeedback({simCase, simPart, setSimPart}) {
    const navigate = useNavigate()

    function handleClick(evt) {
        navigate(`/cases/${simCase._id}/summary`)
    }


    const feedbackMistakes = simCase.partTwoFeedbackMistakes?.map((m, idx) => 
        <div className="flex-ctr-ctr-coll-left" key={idx}>
            <li className="bullet-points">{m}</li>
        </div>)

    const feedbackSuccesses = simCase.partTwoFeedbackSuccesses?.map((s, idx) => 
    <div className="flex-ctr-ctr-col-left" key={idx}>
        <li className="bullet-points">{s}</li>
    </div>)


    return (
        <>
 
            <div className="banner">
                <h2>F<small>EEDBACK</small> O<small>N</small> P<small>ART</small> 2: M<small>ANAGEMENT</small></h2>
            </div>

            <div className="content-below-header">
      
            <div className="center-div">
                <div className="all-data-table-for-feedback overflow-auto">
                    <Data simCase={simCase}/>
                </div>
            </div>

                <div className="all-feedback-lists">


                    <div className="improvement-list">
                        <h2 className="shadow-font">G<small>ROWS:</small></h2>
                        <div className="improvement-list-contents">
                            {simCase.partTwoFeedbackMistakes.length ? feedbackMistakes : <li>great job - no suggestions for improvment!</li>}
                        </div>
                    </div>

                    <div className="success-list">
                        <h2 className="shadow-font">G<small>LOWS:</small></h2>
                        <div className="success-list-contents">
                            {simCase.partTwoFeedbackSuccesses.length ? feedbackSuccesses : <li>great job getting through a challenging case!</li>}
                        </div>
                    </div>
        
                </div>

                <div className="bottom-button-container">
                    <button onClick={handleClick} ><h3>View Case Summary Page</h3></button>
                </div>

            </div>

        </>
    )
}
