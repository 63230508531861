export default function ActiveOrders({simCase, ordersFormData}) {
    // const ordersList = Object.keys(ordersFormData).map((key, idx) => <li>{ordersFormData[key]}</li> || null)
    return (
        <div className="active-orders-ctr overflow-auto active-orders-form ">
            <div className="flex">
                <h4>Most Recent Orders</h4>
            </div>
            <div className="active-orders-text">
                {/* {ordersList} */}
                <ul>
                    {simCase?.clinCurrentState.fluidRestriction ? <li>Fluid restriction</li> : null}
                    {simCase?.clinCurrentState.normalSalineIn ? <li>Normal saline @ {simCase.clinCurrentState.normalSalineIn}mL/hr</li> : null}
                    {simCase?.clinCurrentState.d5wIn ? <li>D5W @ {simCase.clinCurrentState.d5wIn}mL/hr</li> : null}
                    {simCase?.clinCurrentState.hypertonicSalineIn ? <li>Hypertonic saline @ {simCase.clinCurrentState.hypertonicSalineIn}mL/hr</li> : null}
                    {simCase?.clinCurrentState.saltTabsGiven ? <li>Salt tabs was given this interval</li> : null}
                    {simCase?.clinCurrentState.potassiumGiven ? <li>KCL (40 mEq capsule) was given this interval</li> : null}
                    {simCase?.clinCurrentState.furosemideGiven ? <li>Furosemide given at {simCase.clinCurrentState.timeInterval}h</li> : null}
                    {simCase?.clinCurrentState.ddAVPGiven ? <li>ddAVP was given this interval</li> : null}
                    {simCase?.clinCurrentState.tolvaptanGiven ? <li>Tolvaptan given at {simCase.clinCurrentState.timeInterval}h</li> : null}
                    {simCase?.clinCurrentState.ureaGiven ? <li>Urea packets were given this interval</li> : null}
                    <br></br>
                    <br></br>
                    {simCase?.clinCurrentState.serumNaOrderNext ? <li>Serum Na was ordered for {simCase.clinCurrentState.timeInterval}h</li> : null}
                    {simCase?.clinCurrentState.serumKOrderNext ? <li>Serum K was ordered for {simCase.clinCurrentState.timeInterval}h</li> : null}
                    {simCase?.clinCurrentState.urineOsmOrderNext ? <li>Urine Osm was ordered for {simCase.clinCurrentState.timeInterval}h</li> : null}
                    {simCase?.clinCurrentState.urineNaOrderNext ? <li>Urine Na was ordered for {simCase.clinCurrentState.timeInterval}h</li> : null}
                    {simCase?.clinCurrentState.urineKOrderNext ? <li>Urine K was ordered for {simCase.clinCurrentState.timeInterval}h</li> : null}
                </ul>
            </div>
        </div>
    )
}