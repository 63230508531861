export default function Model({simCase}) {

    const mainDivStyle = {
        display: 'flex',
        marginLeft: '-30px'
    }

    const outsideBoxStyle = {
        // backgroundColor: 'blue',
        border: '2px dashed black',
        marginLeft: '50px',
        marginRight: '10px',
        marginTop: '20px',
        paddingTop: '1px',
        height: '154px',
        width: '254px',
        // notice 254, not 250, and 154 not 150, (slightly bigger needed)
        // position: 'relative',
        zIndex: '1'
    };

    const extracellularFluidBoxStyle = {
        backgroundColor: 'rgba(0,118,209,0.5)',
        width: '100px',
        height: '150px',
        marginLeft: '70px',
        marginRight: '10px',
        marginTop: '10px',
        position: 'relative',
        overflow: 'visible',
        top: '-30px',
        left: '80px',
        zIndex: '2'
    };

    const intracellularFluidBoxStyle = {
        backgroundColor: 'rgba(25,25,112,0.5)',
        width: '150px',
        height: '150px',
        marginLeft: '0px',
        marginRight: '10px',
        marginTop: '10px',
        position: 'relative',
        overflow: 'visible',
        top: '-190px',
        left: '0px',
        zIndex: '2'
    };

    const midLineStyle = {
        backgroundColor: 'black',
        width: '3px',
        height: '150px',
        marginLeft: '150px',
        marginRight: '10px',
        marginTop: '10px',
        position: 'relative',
        overflow: 'visible',
        top: '-350px',
        left: '0px',
        zIndex: '3'
    };

    const midLineDottedStyle = {
        // backgroundColor: 'none',
        border: '1.3px dashed black',
        width: '1px',
        height: '150px',
        marginLeft: '150px',
        marginRight: '10px',
        marginTop: '10px',
        position: 'relative',
        overflow: 'visible',
        top: '-30px',
        left: '0px'
    };

    const invisibleStyle = {
        visibility: 'hidden'
    }

    const ICFluidTextStyle = {
        position: 'absolute',
        marginTop: '52px',
        marginLeft: '-28px',
        fontSize: '15px',
        color: 'black'
    }

    const idealICFTextStyle = {
        position: 'absolute',
        marginTop: '160px',
        marginLeft: '-90px',
        fontSize: '13px',
        color: 'black'
    }

    const ECFluidTextStyle = {
        position: 'absolute',
        marginTop: '52px',
        marginLeft: '-28px',
        fontSize: '15px',
        color: 'black'
    }

    const ECFluidTextStyle2 = {
        position: 'absolute',
        marginTop: '20px',
        marginLeft: '-25px',
        fontSize: '12px',
        color: 'black'
    }

    const ECFluidTextStyle3 = {
        position: 'absolute',
        marginTop: '35px',
        marginLeft: '-15px',
        fontSize: '12px',
        color: 'black'
    }

    const ECFluidTextStyle4 = {
        position: 'absolute',
        marginTop: '80px',
        marginLeft: '-25px',
        fontSize: '12px',
        color: 'black'
    }

    const ECFluidTextStyle5 = {
        position: 'absolute',
        marginTop: '95px',
        marginLeft: '-32px',
        fontSize: '12px',
        color: 'black'
    }

    const ECFluidTextStyle6 = {
        position: 'absolute',
        marginTop: '112px',
        marginLeft: '-38px',
        fontSize: '13px',
        color: 'black'
    }


    const idealECFTextStyle = {
        position: 'absolute',
        marginTop: '160px',
        marginLeft: '34px',
        fontSize: '13px',
        color: 'black'
    }


    const idealICOsmTextStyle = {
        position: 'absolute',
        marginTop: '153px',
        marginLeft: '-90px',
        fontSize: '13px',
        color: 'black'
    }


    const idealECOsmTextStyle = {
        position: 'absolute',
        marginTop: '153px',
        marginLeft: '30px',
        fontSize: '13px',
        color: 'black'
    }

    const entireContainerStyle = {
        position: 'absolute',
        marginLeft: '0px'
    }

    const extracellularTitle = {

    }

    const intracellularTitle = {
        
    }

    const currentICSolute = simCase.clinCurrentState.intracellularSolute.toFixed(0)
    const currentECSolute = simCase.clinCurrentState.extracellularSolute.toFixed(0)

    // code to render changes in position

    // if (simCase.clinCurrentState.fluidRestriction) {
    //     midLineDottedStyle.marginTop = "200px"
    //     console.log("changing position")
    // }

    // cache relevant variables for water

    let idealECFVar = simCase.idealECF
    let idealICFVar = simCase.idealICF

    let currentECFVar = simCase.clinCurrentState.extracellularWater
    let currentICFVar = simCase.clinCurrentState.intracellularWater

    let difInECF = (currentECFVar - idealECFVar)
        // negative number means it will be smaller

    let difInICF = (currentICFVar - idealICFVar)
        // negative number means it will be smaller

    let difInTBW = (simCase.clinCurrentState.totalBodyWater - simCase.idealTotalBodyWater)
        // negative number means it will be smaller


        // SHRINKING of ECF 
        if (difInECF < -3.5) {
            extracellularFluidBoxStyle.width = "60px"
        } else if (difInECF < -2.5) {
            extracellularFluidBoxStyle.width = "70px"
        } else if (difInECF < -1.5) {
            extracellularFluidBoxStyle.width = "80px"
        } else if (difInECF < -0.5) {
            extracellularFluidBoxStyle.width = "90px"
        }
        // EXPANDING of ECF 
        else if (difInECF < 0.1) {
            extracellularFluidBoxStyle.width = "100px"
        } else if (difInECF < 0.5) {
            extracellularFluidBoxStyle.width = "105px"
        } else if (difInECF < 1.5) {
            extracellularFluidBoxStyle.width = "110px"
        }  else if (difInECF < 2.5) {
            extracellularFluidBoxStyle.width = "120px"
        }  else if (difInECF < 3.5) {
            extracellularFluidBoxStyle.width = "130px"
        } else {
            extracellularFluidBoxStyle.width = "140px"
        }

         // SHRINKING of ICF
         if (difInICF < -3.5) {
            intracellularFluidBoxStyle.width = "110px"
            intracellularFluidBoxStyle.marginLeft = "40px"
        } else if (difInICF < -2.5) {
            intracellularFluidBoxStyle.width = "120px"
            intracellularFluidBoxStyle.marginLeft = "30px"
        } else if (difInICF < -1.5) {
            intracellularFluidBoxStyle.width = "130px"
            intracellularFluidBoxStyle.marginLeft = "20px"
        } else if (difInICF < -0.5) {
            intracellularFluidBoxStyle.width = "140px"
            intracellularFluidBoxStyle.marginLeft = "10px"
        }
        // EXPANDING of ICF 
        else if (difInICF < 0.1) {
            intracellularFluidBoxStyle.width = "150px"
        } else if (difInICF < 0.5) {
            intracellularFluidBoxStyle.width = "155px"
            intracellularFluidBoxStyle.marginLeft = "-5px"
        } else if (difInICF < 1.5) {
            intracellularFluidBoxStyle.width = "160px"
            intracellularFluidBoxStyle.marginLeft = "-10px"
        }  else if (difInICF < 2.5) {
            intracellularFluidBoxStyle.width = "170px"
            intracellularFluidBoxStyle.marginLeft = "-20px"
        }  else if (difInICF < 3.5) {
            intracellularFluidBoxStyle.width = "180px"
            intracellularFluidBoxStyle.marginLeft = "-30px"
        } else {
            intracellularFluidBoxStyle.width = "190px"
            intracellularFluidBoxStyle.marginLeft = "-40px"
        }
   

        // shifting of baseline:

        // if BOTH compartments are bigger than baseline, no shift
        // if BOTH compartments are smaller than baseline, no need to shift

        // if ECF is bigger than baseline but ICF is smaller than baseline, shift left
        // if ICF is bigger than baseline but ECF is smaller than baseline, shift right

       if (difInTBW < 0) { 

        if ((difInECF > 0) && (difInICF < 0)) {

              if (difInECF > 3.5) {
                entireContainerStyle.marginLeft = "-40px"
            } else if (difInECF > 2.5) {
                entireContainerStyle.marginLeft = "-30px"
            } else if (difInECF > 1.5) {
                entireContainerStyle.marginLeft = "-20px"
            } else if (difInECF > 0.5) {
                entireContainerStyle.marginLeft = "-10px"
            }

        }

        if ((difInICF > 0) && (difInECF < 0)) {

            if (difInICF > 3.5) {
              entireContainerStyle.marginLeft = "40px"
          } else if (difInICF > 2.5) {
              entireContainerStyle.marginLeft = "30px"
          } else if (difInICF > 1.5) {
              entireContainerStyle.marginLeft = "20px"
          } else if (difInICF > 0.5) {
              entireContainerStyle.marginLeft = "10px"
          }
      }

    }


    return (

        <div className="model-ctr flex">
            <h4>Fluid Compartments</h4>
        <div className="model-ctr-contents">
            <div style={mainDivStyle}>
              <div className="outside-model-box" style={outsideBoxStyle}>
                <p style={idealICFTextStyle}>Ideal ICF: {simCase.idealICF.toFixed(0)} L</p>
                <p style={idealECFTextStyle}>Ideal ECF: {simCase.idealECF.toFixed(0)} L</p>
                {/* <p style={idealICOsmTextStyle}>Ideal ICF Solute: <br></br> {simCase.idealICOsm.toFixed(0)} <small>mOsm</small> </p> */}
                {/* <p style={idealECOsmTextStyle}>Ideal ECF Solute: <br></br> {simCase.idealECOsm.toFixed(0)} <small>mOsm</small> </p> */}
              <p style={invisibleStyle}>-</p>
              <div style={entireContainerStyle}>
                <div style={extracellularFluidBoxStyle}>
                <p style={invisibleStyle}>-</p>
                <p style={ECFluidTextStyle2}>Current</p>
                <p style={ECFluidTextStyle3}>ECF:</p>
                <p style={ECFluidTextStyle}><strong>{simCase.clinCurrentState.extracellularWater.toFixed(1)} L</strong></p>
                {/* <p style={ECFluidTextStyle4}>Current</p>
                <p style={ECFluidTextStyle5}>EC Solute:</p>
                <p style={ECFluidTextStyle6}>{currentECSolute} <small>mOsm</small></p> */}
                </div>
                <div style={intracellularFluidBoxStyle}>
                <p style={invisibleStyle}>-</p>
                <p style={ECFluidTextStyle2}>Current</p>
                <p style={ECFluidTextStyle3}>ICF:</p>
                    <p style={ICFluidTextStyle}><strong>{simCase.clinCurrentState.intracellularWater.toFixed(1)} L</strong></p>
                    {/* <p style={ECFluidTextStyle4}>Current</p>
                <p style={ECFluidTextStyle5}>IC Solute:</p>
                <p style={ECFluidTextStyle6}>{currentICSolute} <small>mOsm</small></p> */}
                </div>
                <div style={midLineStyle}>
                    <p style={invisibleStyle}>-</p>
                </div>
                </div>
                <div style={midLineDottedStyle}>
                    <p style={invisibleStyle}>-</p>
                </div>
            </div>   
            </div> 
            </div>
        </div>
    );
}