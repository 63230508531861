export default function PhysExam({returnToSim, simCase, simPart}) {
    const examsList = Object.keys(simCase.physicalExam).map((key, idx) => 
    <tr key={idx}>
        <th>
            {key}
        </th>
        <td>
            {simCase.physicalExam[key]}
        </td>
    </tr>
    )
    return (
        <div className="phys-exam-ctr sim-modal">
            <h2>Physical Examination</h2>


            <p><strong>Date: </strong>{new Date(simCase.createdAt).toDateString()}</p>
            <p><strong>Time: </strong>{new Date(simCase.createdAt).toLocaleTimeString()}</p>
                    
                <br></br>

            <table>
                {examsList}
            </table>
            <button className="return" onClick={returnToSim}>{simPart === 2 ? <h3>Return to Patient</h3> : <h3>Return to Feedback</h3>}</button>
        </div>
    )
}