import { Link, useNavigate } from "react-router-dom"
import { deleteCase } from "../../utilities/cases-service"

export default function CaseItem({simCase, idx, handleRequest }) {

    const navigate = useNavigate()
    async function handleDelete() {
        try {
            const deleteResponse = await deleteCase(simCase._id)
            if (deleteResponse._id) {
                handleRequest()
            } else {
                throw Error("something went wrong")
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <tr key={idx}>
            <td><button onClick={() => navigate(`/cases/${simCase._id}/summary`)}>View Case</button></td>
            <td>{simCase.name}</td>
            <td>{simCase.primaryIllnessScript}</td>
            <td>{new Date(simCase.createdAt).toDateString()} {new Date(simCase.createdAt).toLocaleTimeString()}</td>
            <td>{simCase._id.slice(18)}</td>
            <td className="delete-cell">
                <button className="delete" onClick={handleDelete}><h4>Remove</h4></button>
            </td>
        </tr>
    )
}