import { FaUserDoctor, FaPeopleGroup } from 'react-icons/fa6';
import { TbAugmentedReality2 } from 'react-icons/tb';
import { BsLinkedin, BsGithub } from 'react-icons/bs';
import { HiChatBubbleOvalLeftEllipsis } from 'react-icons/hi2';
import "./AboutPage.css"
import * as aboutText from "./aboutText"

export default function AboutPage() {
    return (
       
    
    <div className="about-ctr ctr-header-and-content">

        <div className="banner">
            <div className="flex-ctr-ctr-col">
                <h1>H<small>YPONATREMIA</small> S<small>IMULATOR</small></h1>
            </div>
        </div>

        <div className="content-below-header">

            <section className="about-section flex-ctr-ctr-col">
            <br></br>
    
            <div className="flex-ctr-ctr">

                {/* <div className="about-icon-div">
                    <FaUserDoctor className="about-icon" />
                </div> */}
 
                </div>

            {/* <div className="our-team flex-ctr-ctr-space-even"> */}
            <div className="our-team">

                <div className="profile flex-ctr-ctr">

                    <div className="about-section-text navy-background">

                        <img className="headshot" src="https://i.imgur.com/x4lnmgB.jpg" alt="" />

                        <h4>Robert Hayden, MD</h4>

                        <div className="social-media-icon-div align-center">
                            <a href="https://www.linkedin.com/in/robert-m-hayden/" target="_blank">
                                <BsLinkedin className="social-media-icon"/>
                            </a>
                            <a href="https://github.com/rmhayden" target="_blank">
                                <BsGithub className="social-media-icon"/>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="profile flex-ctr-ctr">
                                    
                    <div className="about-section-text navy-background">
                        <img className="headshot" src="https://i.imgur.com/WTYGJfC.jpg" alt="" />
                        <h4>William Hu, RN</h4>
                                
                            <div className="social-media-icon-div align-center">
                                <a href="https://www.linkedin.com/in/william-wl-hu/" target="_blank">
                                    <BsLinkedin className="social-media-icon"/>
                                </a>
                                <a href="https://github.com/william-hu-codes" target="_blank">
                                    <BsGithub className="social-media-icon"/>
                                </a>
                            </div>
                    </div>
                </div>

            </div>

            <div className="about-section-text flex-ctr-ctr-col">
                    <p>{aboutText.introduction}</p>
                </div>


            </section>

            <section className="about-section flex-ctr-ctr-col">
                <br></br>
                <h2 className="section-header-text"><i>Urine<small>-</small></i>dispensible Simulator</h2>
                <div className="flex-ctr-ctr">
                    <div className="about-icon-div">
                        <HiChatBubbleOvalLeftEllipsis className="about-icon" />
                        {/* <TbAugmentedReality2 className="about-icon" /> */}
                    </div>
                    <div className="about-section-text flex-col-start">
                        <h3>Patient Interaction</h3>
                        <p>{aboutText.keyFeatures.patientInteraction}</p>
                        <h3>Treatment Simulation</h3>
                        <p>{aboutText.keyFeatures.treatmentSimulation}</p>
                        <h3>Feedback</h3>
                        <p>{aboutText.keyFeatures.feedback}</p>
                    </div>
                </div>
            </section>

            <section className="about-section flex-ctr-ctr-col">
                <br></br>
                <h2 className="section-header-text">AI Integration</h2>
                <div className="flex-ctr-ctr">
                    <div className="about-icon-div">
                        <TbAugmentedReality2 className="about-icon" />
                    </div>
                    <div className="about-section-text flex-col-start">
                        <p>{aboutText.keyFeatures.openAIIntegration}</p>
                    </div>
                </div>
            </section>

            <section className="about-section flex-ctr-ctr-col">
                <br></br>
                <h2 className="section-header-text">Our Mission</h2>
                <div className="flex-ctr-ctr">
                    <div className="about-icon-div">
                        <FaPeopleGroup className="about-icon" />
                    </div>
                    <div className="about-section-text flex-ctr-ctr-col">
                        <p>{aboutText.missionStatement}</p>
                    </div>
                </div>
            </section>
         

            {/* <section className="about-section flex-ctr-ctr-col">

                
                <div className="our-team flex-ctr-ctr-space-even">

                    <div className="profile flex-ctr-ctr">

                    <div className="about-section-text navy-background">

                        <img className="headshot" src="https://i.imgur.com/x4lnmgB.jpg" alt="" />
                        
                            <h3>Robert Hayden, MD</h3>
                   
                            <div className="social-media-icon-div align-center">
                                <a href="https://www.linkedin.com/in/robert-m-hayden/" target="_blank">
                                    <BsLinkedin className="social-media-icon"/>
                                </a>
                                <a href="https://github.com/rmhayden" target="_blank">
                                    <BsGithub className="social-media-icon"/>
                                </a>
                            </div>
                        </div>
                    </div> */}

{/* 
                    <div className="profile flex-ctr-ctr">
                        
                        <div className="about-section-text navy-background">
                        <img className="headshot" src="https://i.imgur.com/WTYGJfC.jpg" alt="" />
                            <h3>William Hu, RN</h3>
                         
                            <div className="social-media-icon-div align-center">
                            <a href="https://www.linkedin.com/in/william-wl-hu/" target="_blank">
                                    <BsLinkedin className="social-media-icon"/>
                                </a>
                                <a href="https://github.com/william-hu-codes" target="_blank">
                                    <BsGithub className="social-media-icon"/>
                                </a>
                            </div>
                        </div>


                    </div>
                </div>
            </section> */}


        </div>
        </div>
    )
}