import {Routes, Route} from 'react-router-dom'

import CasesIndexPage from '../../pages/CasesIndexPage/CasesIndexPage'
import ErrorPage from '../../pages/ErrorPage/ErrorPage'
import AuthPage from '../../pages/AuthPage/AuthPage'
import HomePage from '../../pages/HomePage/HomePage'
import AboutPage from '../../pages/AboutPage/AboutPage'
import "./Main.css"
import UserShowPage from '../../pages/UserShowPage/UserShowPage'
import NewCasePage from '../../pages/NewCasePage/NewCasePage'
import UserEditPage from '../../pages/UserEditPage/UserEditPage'
import SimulationPage from '../../pages/SimulationPage/SimulationPage'
import CaseSummaryPage from '../../pages/CaseSummaryPage/CaseSummaryPage'
import RenalPrimers from '../../pages/RenalPrimers/RenalPrimers'


export default function Main({user, setUser}){


    return(
        <main className="main">
        { user ? 
        <>
          <Routes>
            <Route path="/" element={<AboutPage />} /> 
            <Route path="/sims/hyponatremia" element={<RenalPrimers/>} />
            {/* <Route path="/" element={<HomePage />} />  */}
            <Route path="/user" element={<UserShowPage user={user} setUser={setUser} />} /> 
            <Route path="/user/edit" element={<UserEditPage user={user} setUser={setUser} />} />
            <Route path="/cases" element={<CasesIndexPage />} /> 
            <Route path="/cases/:caseid" element={<SimulationPage />} /> 
            <Route path="/cases/:caseid/summary" element={<CaseSummaryPage />} /> 
            <Route path="/new-case" element={<NewCasePage user={user}/>} /> 
              {/* added user to this route */}
            <Route path="/*" element={<ErrorPage />} />   
          </Routes>
        </>
          :
    
          <AuthPage setUser={setUser} />
      }
        </main>
    )
}

