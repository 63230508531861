import { useParams, useNavigate } from "react-router-dom"
import { useState } from "react"
import { getCaseSummary } from "../../utilities/cases-service"
import Loader from "../../components/Loader/Loader"
import { useEffect } from "react"
import Feedback from "../SimulationPage/SimulationModules/PartOneFeedback/Feedback"
import PartTwoFeedback from "../SimulationPage/SimulationModules/PartTwoFeedback/PartTwoFeedback"
import Data from "../SimulationPage/SimulationModules/PartTwoSim/Data"
import "./CaseSummaryPage.css"


export default function CaseSummaryPage() {

    const{caseid} = useParams()
    const navigate = useNavigate()
    const [simCase, setSimCase] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const [activeComponent, setActiveComponent] = useState(null)
    


    async function handleRequest() {
        try {
            const response = await getCaseSummary(caseid)
            setSimCase(response)
            setIsLoading(false)
            console.log("are we getting response?", response)
        }catch(error) {
            console.log(error)
            navigate("/")
        }
    }
    console.log(simCase)

    useEffect(() => {
        handleRequest();
      }, [])

    const ourDiagnosticsList = simCase?.ourAnswerDiagnostics.map((d, idx) => <li key={idx}>{d}</li>)
    const userDiagnosticsList = simCase?.userDiagnostics.map((e, idx) => <li key={idx} >{e}</li>)
    const ourTherapeuticsList = simCase?.ourAnswerTherapeutics.map((f, idx) => <li key={idx} >{f}</li>)
    const userTherapeuticsList = simCase?.userTherapeutics.map((g, idx) => <li key={idx}>{g}</li> )

    const feedbackMistakes = simCase?.partTwoFeedbackMistakes?.map((m, idx) => 
    <div className="flex-ctr-ctr-coll-left" key={idx}>
        <li className="bullet-points">{m}</li>
    </div>)

    const feedbackSuccesses = simCase?.partTwoFeedbackSuccesses?.map((s, idx) => 
    <div className="flex-ctr-ctr-col-left" key={idx}>
        <li className="bullet-points">{s}</li>
    </div>)
    
    // const labsList = Object.keys(simCase.initialClinData.labs).map((key, idx) => 
    // <tr key={idx}>
    //     <th>
    //         {key}
    //     </th>
    //     <td>
    //         {simCase.initialClinData.labs[key]}
    //     </td>
    // </tr>
    // )

    return isLoading ? (
        <Loader />
    ) : (


        <div className="case-summary-ctr">

            <div className="title-summary-page">
                <h2 className="banner">Simulation Summary</h2>
            </div>

            <div className="spacer-down"></div>

            <div className="content-below-header">
      
      <div className="margins-for-case-table">
            <table className="case-information-table">
                        <thead>
                            <tr>
                                <th>Patient Name</th>
                                <th>Sex</th>
                                <th>Pronouns</th>
                                <th>Primary Illness</th>
                                <th>Date Created</th>
                                <th>Case ID</th>
                            </tr> 
                        </thead>
                        <tbody>
                            <tr>
                                <td>{simCase.name}</td>
                                <td>{simCase.sex}</td>
                                <td>{simCase.pronouns}</td>
                                <td>{simCase.primaryIllnessScript}</td>
                                <td>{new Date(simCase.createdAt).toDateString()} {new Date(simCase.createdAt).toLocaleTimeString()}</td>
                                <td>{simCase._id.slice(18)}</td>
                            </tr>
                        </tbody>
                    </table>
        </div>

            <br></br>
            <br></br>

               <div className="case-information flex-ctr-ctr-col">
                    <h3 className="sub-banner">Patient Information from Part 1: Initial Evaluation</h3>
                    <br />

                    <div className="triage-note">
                        <h4>Initial History</h4>
                        <div className="justify-left text-left-align gap-above">
                            <p>{simCase.initialOpenAIHistory}</p>
                        </div>
                    </div>

                    <div className="medical-record-grid">

                        <br></br>
                        <div className="medical-record-grid-1">
                            <h3>Medical History</h3>
                            <div className="justify-left text-left-align gap-above">
                                <ul className="text-left-align">
                                    {simCase.pastMedicalHistoryArray?.map((m, idx) => <li key={idx}>{m}</li>)}
                                </ul>
                            </div>
                        </div>
            
                        <div className="medical-record-grid-2">
                            <h3>Home Medications</h3>
                            <div className="justify-left text-left-align gap-above">
                                <ul className="text-left-align">
                                    {simCase.medicationsArray?.map((m, idx) => <li key={idx}>{m}</li>)}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <br></br>

                    <div className="triage-note">
                        <h4>Triage Nurse Intake Note</h4>
                        <div className="justify-left text-left-align gap-above">
                            <p>{simCase.initialOpenAIHPI}</p>
                        </div>
                    </div>



                    
                    <div className="triage-note labs-tables-ctr">
                        <div className="flex-ctr-ctr-col gap-20">
                            {/* <p><strong>Date: </strong>{new Date(simCase.createdAt).toDateString()}</p>
                            <p><strong>Time: </strong>{new Date(simCase.createdAt).toLocaleTimeString()}</p>
                            <br></br> */}
                            <div>
                                <h3 className="gap-down">Serum Tonicity Labs</h3>
                                <table>
                                    <tr>
                                        <th><h5>Serum Sodium</h5></th>
                                        <td>{simCase.initialSerumNa} mEq/L</td>
                                    </tr>
                                    <tr>
                                        <th><h5>Serum Osmolality</h5></th>
                                        <td>{simCase.initialSerumOsm + 10} mOsm/kg</td>
                                    </tr>
                                </table>
                            </div>
                            <br></br>
                            <div>
                                <h3 className="gap-down">Urine Chemistries</h3>
                                <table>
                                    <tr>
                                        <th><h5>Urine Osmolality</h5></th>
                                        <td>{simCase.initialUrineOsm} mOsm/kg</td>
                                    </tr>
                                    <tr>
                                        <th><h5>Urine Na</h5></th>
                                        <td>{simCase.initialUrineSodium} mEq/L</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div>
                            <h3 className="gap-down">Additional Results</h3>
                            <table>
                                {/* {labsList} */}
                                {Object.keys(simCase.initialClinData.labs).map((key, idx) => 
                                    <tr key={idx}>
                                        <th>
                                            {key}
                                        </th>
                                        <td>
                                            {simCase.initialClinData.labs[key]}
                                        </td>
                                    </tr>
                                )}
                            </table>
                        </div>
                    </div>
                </div> 
            <div className="spacer-down"></div>
            <br></br>
            <br></br>
                <div className="part-one-summary">
                    <h3 className="sub-banner">Feedback from Part One: Initial Evaluation</h3>
                    <div className="feedback">
                        <div className="comparison-table-div">
                            <div className="comparison-table ">
                                <div className="compare-impressions-ctr">
                                    <div className="your-impression flex-ctr-ctr-col">
                                        <h4 className="sub-banner2">Your Impression</h4>
                                        {simCase.userImpression}
                                    </div>
                                    <div className="our-impression flex-ctr-ctr-col align-left">
                                        <h4 className="sub-banner2">Our Impression</h4>
                                        <p className="padding-summary-page">{simCase.ourAnswerImpression}</p>
                                    </div>
                                </div>
                                {/* <table>
                                    <tr className="feedback-table-section">
                                        <th><h5>Your Impression</h5></th>
                                        <td >{simCase.userImpression}</td>
                                    </tr>
                                    <tr className="feedback-table-section">
                                        <th><h5>Our Impression</h5></th>
                                        <td>{simCase.ourAnswerImpression}</td>
                                    </tr>
                                </table> */}
                            </div>
                            <br></br>
                            <div>
                                
                                <br />
                                <div className="comparison-ctr">
                                    <div className="yours">
                                        <h4 >Your Plan</h4>
                                        <div className="list">
                                            <ul>
                                                {userDiagnosticsList}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="ours">
                                        <h4>Our Plan</h4>
                                        <div className="list">
                                            <ul>
                                                {ourDiagnosticsList}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div>
                   
                                <br />
                                <div className="comparison-ctr">
                                    <div className="yours">
                                        <h4 >Your Therapeutics</h4>
                                        <div className="list">
                                            <ul>
                                                {userTherapeuticsList}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="ours">
                                        <h4>Our Therapeutics</h4>
                                        <div className="list">
                                            <ul>
                                                {ourTherapeuticsList}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="spacer-down"></div>
            <br></br>
            <br></br>
                <div className="part-two-summary">
                    <div className="z-index-2">
                        <h3 className="sub-banner">Feedback from Part Two: Management</h3>
                    </div>


                <div className="table-top-box"></div>
                
                <div className="data-table-outer-container">

                    <div className="table-side-box-left"></div>

                      <div className="overflow-auto">
                          <Data simCase={simCase}/>
                       </div>

                    <div className="table-side-box-right"></div>

                </div>

            
                    <div className="all-feedback-lists">
              

                        <div className="improvement-list">
                            <h2 className="sub-banner">Grows</h2>
                            <div className="improvement-list-contents">
                                {simCase.partTwoFeedbackMistakes.length ? feedbackMistakes : <li>Great job - no suggestions for improvment</li>}
                            </div>
                        </div>

                        <div className="success-list">
                            <h2 className="sub-banner">Glows</h2>
                            <div className="success-list-contents">
                                {simCase.partTwoFeedbackSuccesses.length ? feedbackSuccesses : <li>You made it through a challenging case</li>}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
    

}