export default function Data({simCase}) {
    // const dataList = Object.keys(simCase.clinData).map((key, idx) =>  ) 
    // const dataList = []
    // const includeKeys = ["serumSodium", "urineOsm", "urineSodium", "urinePotassium", "hourlyWaterIn", "normalSalineIn", "d5wIn", "hypertonicSalineIn", "hourlyWaterOut", "saltTabsGiven", "furosemideGiven", "ddAVPGiven",]
    // for (let key in simCase.clinData) {
    //     for (let dataKey in simCase.clinData[key]) {
    //         // if (includeKeys.includes(dataKey)) {
    //             dataList.push(
    //                 <tr>
    //                     <td>simCase.clinData[key][dataKey]</td>
    //                 </tr>
    //             )
    //         // }
    //     }
    // }

    // const dataList0 = Object.keys(simCase.clinData.clinTime0).map((key, idx) => <tr>includeKeys.inclue(key) ? simCase.clinData.clinTime0[key] : null</tr>)

    return (
        <div className="data-table flex-col-btw ">
                {/* <h4 className="pertinent-data">Pertinent Data Trends</h4> */}
            <div className="table-ctr">
                <table>
                    <tr className="bg-grey">
                        <th className="new-table-header-top-left align-right">TIME</th>
                        <th className="new-table-header-top bg-grey align-left">INTERVAL</th>
                        <th>&nbsp;0h&nbsp; </th>
                        <th>&nbsp;1h&nbsp;</th>
                        <th>&nbsp;2h&nbsp;</th>
                        <th>&nbsp;3h&nbsp;</th>
                        <th>&nbsp;4h&nbsp;</th>
                        <th>&nbsp;5h&nbsp;</th>
                        <th>&nbsp;6h&nbsp;</th>
                        <th>&nbsp;7h&nbsp;</th>
                        <th>&nbsp;8h&nbsp;</th>
                        <th>&nbsp;9h&nbsp;</th>
                        <th>10h&nbsp;</th>
                        <th>11h&nbsp;</th>
                        <th>12h&nbsp;</th>
                        <th>13h&nbsp;</th>
                        <th>14h&nbsp;</th>
                        <th>15h&nbsp;</th>
                        <th>16h&nbsp;</th>
                        <th>17h&nbsp;</th>
                        <th>18h&nbsp;</th>
                        <th>19h&nbsp;</th>
                        <th>20h&nbsp;</th>
                        <th>21h&nbsp;</th>
                        <th>22h&nbsp;</th>
                        <th>23h&nbsp;</th>
                        <th>24h&nbsp;</th>
                    </tr> 
                    <tr className="bg-blue">
                        <th className="new-table-header-left align-left"></th>
                        <th className="new-table-header bg-blue align-left">Serum Na</th>
                        {/* <td>{simCase.initalSerumNa || "-"}</td> */}
                        <td>{simCase?.clinData.clinTime0[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime1[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime2[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime3[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime4[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime5[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime6[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime7[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime8[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime9[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime10[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime11[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime12[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime13[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime14[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime15[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime16[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime17[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime18[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime19[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime20[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime21[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime22[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime23[0]?.serumSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime24[0]?.serumSodium || "-"}</td>
                    </tr>
                    <tr className="bg-blue">
                        <th className="new-table-header-left align-left"></th>
                        <th className="new-table-header bg-blue align-left">Serum K</th>
                        {/* <td>{simCase.initalSerumNa || "-"}</td> */}
                        <td>{simCase?.clinData.clinTime0[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime1[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime2[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime3[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime4[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime5[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime6[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime7[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime8[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime9[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime10[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime11[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime12[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime13[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime14[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime15[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime16[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime17[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime18[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime19[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime20[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime21[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime22[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime23[0]?.serumPotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime24[0]?.serumPotassium || "-"}</td>
                    </tr>
                    <tr className="bg-blue">
                        <th className="new-table-header-left align-left">LABS</th>
                        <th className="align-left bg-blue new-table-header">Urine Osm</th>
                        <td>{simCase?.clinData.clinTime0[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime1[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime2[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime3[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime4[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime5[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime6[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime7[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime8[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime9[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime10[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime11[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime12[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime13[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime14[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime15[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime16[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime17[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime18[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime19[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime20[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime21[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime22[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime23[0]?.urineOsm || "-"}</td>
                        <td>{simCase?.clinData.clinTime24[0]?.urineOsm || "-"}</td>
                    </tr>
                    
                    <tr className="bg-blue">
                        <th className="new-table-header-left"></th>
                        <th className="new-table-header bg-blue align-left">Urine Na</th>
                        <td>{simCase?.clinData.clinTime0[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime1[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime2[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime3[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime4[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime5[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime6[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime7[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime8[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime9[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime10[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime11[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime12[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime13[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime14[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime15[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime16[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime17[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime18[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime19[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime20[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime21[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime22[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime23[0]?.urineSodium || "-"}</td>
                        <td>{simCase?.clinData.clinTime24[0]?.urineSodium || "-"}</td>
                    </tr>
                    <tr className="bg-blue">
                        <th className="new-table-header-left"></th>
                        <th className="new-table-header bg-blue align-left">Urine K</th>
                        <td>{simCase?.clinData.clinTime0[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime1[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime2[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime3[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime4[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime5[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime6[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime7[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime8[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime9[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime10[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime11[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime12[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime13[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime14[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime15[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime16[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime17[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime18[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime19[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime20[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime21[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime22[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime23[0]?.urinePotassium || "-"}</td>
                        <td>{simCase?.clinData.clinTime24[0]?.urinePotassium || "-"}</td>
                    </tr>
                    <tr className="bg-yellow">
                        <th className="new-table-header-top-2-left align-left"></th>
                        <th className="new-table-header-top-2 bg-yellow align-left">Total Ins/Hr (mL)</th>
                        <td>{simCase?.clinData.clinTime0[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime1[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime2[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime3[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime4[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime5[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime6[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime7[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime8[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime9[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime10[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime11[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime12[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime13[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime14[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime15[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime16[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime17[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime18[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime19[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime20[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime21[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime22[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime23[0]?.hourlyWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime24[0]?.hourlyWaterIn || "-"}</td>
                    </tr>

                    <tr className="bg-yellow">
                        <th className="new-table-header-left align-left"></th>
                        <th className="new-table-header bg-yellow align-left">PO Ins/Hr (mL)</th>
                        <td>{simCase?.clinData.clinTime0[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime1[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime2[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime3[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime4[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime5[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime6[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime7[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime8[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime9[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime10[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime11[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime12[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime13[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime14[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime15[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime16[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime17[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime18[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime19[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime20[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime21[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime22[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime23[0]?.hourlyPOWaterIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime24[0]?.hourlyPOWaterIn || "-"}</td>
                    </tr>

                    <tr className="bg-yellow">
                        <th className="new-table-header-left align-left">FLUIDS</th>
                        <th className="new-table-header bg-yellow align-left">Normal Saline</th>
                        <td>{simCase?.clinData.clinTime0[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime1[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime2[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime3[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime4[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime5[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime6[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime7[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime8[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime9[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime10[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime11[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime12[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime13[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime14[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime15[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime16[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime17[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime18[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime19[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime20[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime21[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime22[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime23[0]?.normalSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime24[0]?.normalSalineIn || "-"}</td>
                    </tr>
                    <tr className="bg-yellow">
                        <th className="new-table-header-left align-left">IN/OUT</th>
                        <th className="new-table-header bg-yellow align-left">D5W</th>
                        <td>{simCase?.clinData.clinTime0[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime1[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime2[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime3[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime4[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime5[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime6[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime7[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime8[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime9[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime10[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime11[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime12[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime13[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime14[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime15[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime16[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime17[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime18[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime19[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime20[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime21[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime22[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime23[0]?.d5wIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime24[0]?.d5wIn || "-"}</td>
                    </tr>
                    <tr className="bg-yellow">
                        <th className="new-table-header-left"></th>
                        <th className="new-table-header bg-yellow align-left">3% Saline</th>
                        <td>{simCase?.clinData.clinTime0[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime1[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime2[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime3[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime4[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime5[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime6[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime7[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime8[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime9[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime10[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime11[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime12[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime13[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime14[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime15[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime16[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime17[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime18[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime19[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime20[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime21[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime22[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime23[0]?.hypertonicSalineIn || "-"}</td>
                        <td>{simCase?.clinData.clinTime24[0]?.hypertonicSalineIn || "-"}</td>
                    </tr>
                    <tr className="bg-yellow">
                        <th className="new-table-header-left"></th>
                        <th className="new-table-header bg-yellow align-left">Urine/Hr (mL)</th>
                        <td>{simCase?.clinData.clinTime0[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime1[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime2[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime3[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime4[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime5[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime6[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime7[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime8[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime9[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime10[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime11[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime12[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime13[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime14[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime15[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime16[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime17[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime18[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime19[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime20[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime21[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime22[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime23[0]?.hourlyWaterOut || "-"}</td>
                        <td>{simCase?.clinData.clinTime24[0]?.hourlyWaterOut || "-"}</td>
                    </tr>
                    {/* <tr>
                        <th>MEDS GIVEN</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr> */}
                    <tr className="bg-red">
                    <th className="new-table-header-top-2-left align-left"></th>
                        <th className="new-table-header-top-2 bg-red align-left">ddAVP</th>
                        <td>{simCase?.clinData.clinTime0[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime1[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime2[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime3[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime4[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime5[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime6[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime7[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime8[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime9[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime10[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime11[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime12[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime13[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime14[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime15[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime16[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime17[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime18[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime19[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime20[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime21[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime22[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime23[0]?.ddAVPGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime24[0]?.ddAVPGiven ? "✓" : null}</td>
                    </tr>
                    <tr className="bg-red">
                        <th className="new-table-header-left align-left"></th>
                        <th className="new-table-header bg-red align-left">Salt Tabs</th>
                        <td>{simCase?.clinData.clinTime0[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime1[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime2[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime3[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime4[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime5[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime6[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime7[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime8[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime9[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime10[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime11[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime12[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime13[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime14[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime15[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime16[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime17[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime18[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime19[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime20[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime21[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime22[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime23[0]?.saltTabsGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime24[0]?.saltTabsGiven ? "✓" : null}</td>
                    </tr>
                    <tr className="bg-red">
                        <th className="new-table-header-left align-left">MEDS</th>
                        <th className="new-table-header bg-red align-left">Urea Packets</th>
                        <td>{simCase?.clinData.clinTime0[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime1[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime2[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime3[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime4[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime5[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime6[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime7[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime8[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime9[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime10[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime11[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime12[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime13[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime14[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime15[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime16[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime17[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime18[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime19[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime20[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime21[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime22[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime23[0]?.ureaGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime24[0]?.ureaGiven ? "✓" : null}</td>
                    </tr>
                    <tr className="bg-red">
                        <th className="new-table-header-left align-left">GIVEN</th>
                        <th className="new-table-header bg-red align-left">KCl (40 mEq cap)</th>
                        <td>{simCase?.clinData.clinTime0[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime1[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime2[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime3[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime4[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime5[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime6[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime7[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime8[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime9[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime10[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime11[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime12[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime13[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime14[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime15[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime16[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime17[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime18[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime19[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime20[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime21[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime22[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime23[0]?.potassiumGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime24[0]?.potassiumGiven ? "✓" : null}</td>
                    </tr>
                    <tr className="bg-red">
                        <th className="new-table-header-left"></th>
                        <th className="new-table-header bg-red align-left">Furosemide</th>
                        <td>{simCase?.clinData.clinTime0[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime1[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime2[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime3[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime4[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime5[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime6[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime7[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime8[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime9[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime10[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime11[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime12[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime13[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime14[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime15[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime16[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime17[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime18[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime19[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime20[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime21[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime22[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime23[0]?.furosemideGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime24[0]?.furosemideGiven ? "✓" : null}</td>
                    </tr>
                    <tr className="bg-red">
                        <th className="new-table-header-left"></th>
                        <th className="new-table-header bg-red align-left">Tolvaptan</th>
                        <td>{simCase?.clinData.clinTime0[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime1[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime2[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime3[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime4[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime5[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime6[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime7[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime8[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime9[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime10[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime11[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime12[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime13[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime14[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime15[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime16[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime17[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime18[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime19[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime20[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime21[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime22[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime23[0]?.tolvaptanGiven ? "✓" : null}</td>
                        <td>{simCase?.clinData.clinTime24[0]?.tolvaptanGiven ? "✓" : null}</td>
                    </tr>
                </table>

                {/* looping attempt */}
                {/* <table>
                    <tr>
                        <th>TIME</th>
                        <th>SERUM OSM</th>
                        <th>URINE OSM</th>
                        <td>URINE Na</td>
                        <td>URINE K</td>
                        <th>FLUIDS IN/HR</th>
                        <td>Normal Saline</td>
                        <td>D5W</td>
                        <td>Hypertonic (3%) Saline</td>
                        <th>URINE OUTPUT/HR</th>
                        <th>MEDICATIONS GIVEN</th>
                        <td>ddAVP</td>
                        <td>Salt tabs</td>
                        <td>Furosemide</td>
                    </tr>
                    {dataList0}
                </table> */}
            </div>
            <div></div>
        </div>
    )
}