import "./Loader.css"

export default function Loader() {
    return (
        <span>
          <img
            className="loader"
            src={"https://freesvg.org/img/1544764567.png"}
            // TODO: change graphic later
          />
        </span>
    );
  }
  