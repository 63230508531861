import { useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { sendImpressionPlan } from "../../../../utilities/cases-service"

import "./PartOneSim.css"

export default function ImpressionPlan({simCase, returnToSim, simPart, setSimPart}) {

    const navigate = useNavigate()
    const { caseid } = useParams()

    // console.log(caseid)

    const [impressionFormData, setImpressionFormData] = useState("")
    const [diagnosticsFormData, setDiagnosticsFormData] = useState("")
    const [therapeuticsFormData, setTherapeuticsFormData] = useState("")

    const [userDiagnostics, setUserDiagnostics] = useState([])
    const [userTherapeutics, setUserTherapeutics] = useState([])

    function submitDiagnostic(evt) {
        evt.preventDefault()
        const newData = [diagnosticsFormData, ... userDiagnostics]
        setUserDiagnostics(newData)
        setDiagnosticsFormData("")
    }

    function submitTherapeutic(evt) {
        evt.preventDefault()
        const newData = [therapeuticsFormData, ... userTherapeutics]
        setUserTherapeutics(newData)
        setTherapeuticsFormData("")
    }

    async function handleSubmitNext(evt) {
        evt.preventDefault()
        const allData = {
            userImpression: impressionFormData, 
            userDiagnostics, 
            userTherapeutics
        }
        Object.assign(simCase, allData)
        try {
            await sendImpressionPlan(caseid, allData)
            setSimPart(simPart + 1 )
            // navigate(`/cases/${caseid}/summary`)
        } catch(error) {
            console.log(error)
        }
    }

    const diagnosticsList = userDiagnostics.map((d, idx) => d ? <li key={idx}>{d}</li> : null)
    const therapeuticsList = userTherapeutics.map((t, idx) => t ? <li key={idx}>{t}</li> : null)

    return (
        <div className="impression-plan-ctr sim-modal">
            <h2>Impression and Plan</h2>
            <div className="initial-impression">
                <h3>Initial Impression</h3>
                <form >
                    <label>Explain your initial impression of this case. Include your suspected cause of underlying illness.</label>
                    <br />
                    <textarea name="Impression" id="" cols="50" rows="7" value={impressionFormData} onChange={(evt) => setImpressionFormData(evt.target.value)}></textarea>
                </form>
            </div>
            <div className="diagnostics">
                <h3>Diagnostic Plan</h3>
                <form onSubmit={submitDiagnostic}>
                    <label>List diagnostic interventions for this patient.</label>
                    <br />
                    <input type="text" name="diganostics" value={diagnosticsFormData} required onChange={(evt) => setDiagnosticsFormData(evt.target.value)}/>
                    <button type="submit"><h4>Add Diagnostic</h4></button>
                </form>
                <ul className="text-align-diag-thera">
                    {diagnosticsList}
                </ul>
            </div>
            <div className="therapeutics">
                <h3>Therapeutic Plan</h3>
                <form onSubmit={submitTherapeutic}>
                    <label>List therapeutic interventions to treat this patient</label>
                    <br />
                    <input type="text" name="therapeutics" value={therapeuticsFormData} required onChange={(evt) => setTherapeuticsFormData(evt.target.value)}/>
                    <button type="submit"><h4>Add Therapeutic</h4></button>
                </form>
                <ul className="text-align-diag-thera">
                    {therapeuticsList}
                </ul>
            
            </div>
          
          <div className="two-buttons">
            <button className="submission-button" type="submit" onClick={handleSubmitNext}><h3>SUBMIT PLAN</h3></button>
           
            <button className="return" onClick={returnToSim}>{simPart === 2 ? <h3>Return to Patient</h3> : <h3>Return to Feedback</h3>}</button>
          </div>
        </div>
    )
}