export default function Labs({returnToSim, simCase, simPart}) {
    const labsList = Object.keys(simCase.initialClinData.labs).map((key, idx) => 
    <tr key={idx}>
        <th>
            {key}
        </th>
        <td>
            {simCase.initialClinData.labs[key]}
        </td>
    </tr>
    )
    const imagingList = Object.keys(simCase.initialClinData.imaging).map((key, idx) => 
    <tr key={idx}>
        <th>{key}</th>
        <td>
            {simCase.initialClinData.imaging[key] ? simCase.initialClinData.imaging[key] : "Unavailable"}
        </td>
    </tr>
    )
    return (
        <div className="labs-ctr sim-modal">
            {/* <h2>Labs and Imaging</h2> */}
            <h2>Initial Laboratory Values</h2>
            <div className="labs-tables-ctr">
                <div className="flex-ctr-ctr-col gap-20">
                    <p><strong>Date: </strong>{new Date(simCase.createdAt).toDateString()}</p>
                    <p><strong>Time: </strong>{new Date(simCase.createdAt).toLocaleTimeString()}</p>
                    <br></br>
                    <div>
                        <h3 className="gap-down">Serum Tonicity Labs</h3>
                        <table>
                            <tr>
                                <th><h5>Serum Sodium</h5></th>
                                <td>{simCase.initialSerumNa} mEq/L</td>
                            </tr>
                            <tr>
                                <th><h5>Serum Osmolality</h5></th>
                                <td>{simCase.initialSerumOsm + 10} mOsm/kg</td>
                            </tr>
                        </table>
                    </div>
                    <br></br>
                    <div>
                        <h3 className="gap-down">Urine Chemistries</h3>
                        <table>
                            <tr>
                                <th><h5>Urine Osmolality</h5></th>
                                <td>{simCase.initialUrineOsm} mOsm/kg</td>
                            </tr>
                            <tr>
                                <th><h5>Urine Na</h5></th>
                                <td>{simCase.initialUrineSodium} mEq/L</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div>
                    <h3 className="gap-down">Additional Results</h3>
                    <table>
                        {labsList}
                    </table>
                </div>

            </div>

            {/* right now, there is no imaging to show */}

            {/* <div>
                <br></br>
                <h3>Imaging Results</h3>
                <table>
                    {imagingList}
                </table>
            </div> */}
            
            <button className="return" onClick={returnToSim}>{simPart === 2 ? <h3>Return to Patient</h3> : <h3>Return to Feedback</h3>}</button>
        </div>
    )
}