import { checkToken, getLoggedInUser } from "../../utilities/users-service";
import { Link, useNavigate } from "react-router-dom";
// import { AiOutlineExclamationCircle } from 'react-icons/ai'
import "./UserShowPage.css"

export default function UserShowPage ({user, setUser}) {

    // async function handleCheckToken() {
    //     const expDate = await checkToken()
    //     console.log(expDate)
    // }
    const navigate = useNavigate()
    function handleClick() {
        navigate(`/user/edit`)
    }

    return (
        <div className="user-ctr">
            <div className="banner">
                <h1>S<small>ETTINGS</small></h1>
            </div>
          <div className="content-below-header">
            <div className="user-information-ctr flex-ctr-ctr-col">
                <br></br>
                <h2>{user.name}</h2>
                <h4>{user.email}</h4>
                {/* <Link to={`/user/edit`} >
                    <button><h3>Edit profile information</h3></button>
                </Link> */}
                {user.email.includes("@guest") ?
                    <>
                        <button disabled onClick={handleClick}><h3>Edit profile information</h3></button>
                        <div className="guest-info">
                            {/* <AiOutlineExclamationCircle className="exclaimation-icon"/> */}
                            <p>- Profile settings cannot be adjusted on guest accounts. Please sign up for an account to make personalizations. -</p>
                        </div>
                    </>
                    :
                    <button onClick={handleClick}><h3>Edit profile information</h3></button>
                }
            </div> 
            </div>
        </div>
      )
    }