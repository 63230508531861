import "./SimulationPage.css"
// import PartOneInstructions from "./SimulationModules/PartOneInstructions"
// import { useState } from "react"
import { useParams, useNavigate, Link } from "react-router-dom";
import { getCase } from "../../utilities/cases-service";
import Loader from "../../components/Loader/Loader";
import PartOneInstructions from "./SimulationModules/PartOneInstructions/PartOneInstructions";
import { useState, useEffect } from "react"
import PartOneSim from "./SimulationModules/PartOneSim/PartOneSim";
import PartOneFeedback from "./SimulationModules/PartOneFeedback/PartOneFeedback";
import PartTwoInstructions from "./SimulationModules/PartTwoInstructions/PartTwoInstructions";
import PartTwoSim from "./SimulationModules/PartTwoSim/PartTwoSim";
import PartTwoFeedback from "./SimulationModules/PartTwoFeedback/PartTwoFeedback";


export default function SimulationPage() {

    const [simCase, setSimCase] = useState(null)
    const [isLoading, setIsLoading] = useState(true);
    const [simPart, setSimPart] = useState(1)

    const navigate = useNavigate()
    const { caseid } = useParams()
    console.log(caseid)

    async function handleRequest() {
        const caseResponse = await getCase(caseid);
        console.log(simCase)
        console.log(caseid)
        if (caseResponse?._id) {
          setSimCase(caseResponse);
          setIsLoading(false);
        } else {
          navigate('/')
        }
    }

    useEffect(() => {
        handleRequest();
      }, []);

    return (
        isLoading ? (
            <Loader />
        ) : (
            <section className="simulation-ctr">
                {simPart === 1 && <PartOneInstructions simCase={simCase} simPart={simPart} setSimPart={setSimPart} />}
                {simPart === 2 && <PartOneSim simCase={simCase} simPart={simPart} setSimPart={setSimPart} />}
                {simPart === 3 && <PartOneFeedback simCase={simCase} simPart={simPart} setSimPart={setSimPart} />}
                {simPart === 4 && <PartTwoInstructions simCase={simCase} simPart={simPart} setSimPart={setSimPart} />}
                {simPart === 5 && <PartTwoSim simCase={simCase} setSimCase={setSimCase} simPart={simPart} setSimPart={setSimPart} />}
                {simPart === 6 && <PartTwoFeedback simCase={simCase} simPart={simPart} setSimPart={setSimPart} />}
            </section>
        )
    )
}