import { useState, useEffect } from "react";


export default function Clock({handleNextInterval, timeInterval, currentCount, setCount}) {
    // const [currentCount, setCount] = useState(30);
    const timer = () => setCount(currentCount - 1);

    useEffect(
        () => {
            if (currentCount <= 0) {
                handleNextInterval()
                if (timeInterval < 24) {
                    setCount(30)
                } else {
                    return
                    // return () => clearInterval(id);
                }
            }
            const id = setInterval(timer, 1000);
            return () => clearInterval(id);
        },
        [currentCount]
    );

    return <div>{currentCount}</div>;

}