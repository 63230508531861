// importing part one feedback instructions as well

import "../../SimulationModules/PartOneInstructions/PartOneInstructions.css"

export default function PartTwoInstructions({simCase, simPart, setSimPart}) {
    function handleClick(evt) {
        setSimPart(simPart + 1)
    }
    return (
        <div className="part-one-instructions-ctr">
            <div className="banner ">
                {/* <h2 className="flex-ctr-ctr">Part 2: Management</h2> */}
                <h2>P<small>ART </small> 2: M<small>ANAGEMENT</small></h2>
         
            </div>

            <div className="content-below-header">

            <div className="instructions-ctr flex-ctr-ctr-col">
                <div className="instructions align-left">
                <h3 className="align-left gap-down">Instructions</h3>
                    <p>In this crucial part of the simulation, your objective is to actively manage the patient's hyponatremia while maintaining a balance between restoring their serum sodium levels and adhering to the safe rate of increase (not exceeding 6 mmol/L in 24 hours). Here's a step-by-step guide to effectively navigate this phase:</p>
                </div>
                <div className="instructions align-left">
                    <h4>Patient Data Display</h4>
                    <p> Patient information and data will be presented in 1-hour intervals, totaling 24 intervals. This data includes vital information about the patient's condition, such as serum sodium levels, fluid ins/outs, administered medications, and other relevant parameters</p>
                    <br />
                    <h4>Adjusting Medical Orders</h4>
                    <p>To modify the patient's medical orders for the current time interval, click the <em>"Change Order(s)"</em> button located in the bottom navigation bar. This action allows you to make real-time decisions regarding fluid intake, medication, and other treatments to address the patient's hyponatremia.</p>
                    <br />
                    {/* if we implement setInterval, will need to update the below section */}
                    <h4>Progressing to the Next Interval</h4> 
                    <p>After making necessary adjustments for the current hour, press the <em>"Next Interval"</em> button on the bottom navigation bar to proceed to the next hourly interval. Keep in mind that your decisions in each interval will impact the patient's overall treatment outcome.</p>
                </div>

                <div className="instructions align-left">
                    <h4>Fluid compartments Visualization</h4>
                    <p>For a clearer understanding of the patient's fluid balance, you can review the <em>"Fluid Compartments"</em>  modal. This visualization provides a model of the patient's current intracellular and extracellular compartments, helping you visualize the fluid dynamics and make informed decisions about fluid management.</p>
                    <br />
                    <h4>Achieving the Goal</h4>
                    <p>Your primary objective is to raise the patient's serum sodium levels while adhering to the safe rate of increase (6 mmol/L in 24 hours). Be mindful of the balance between fluid intake, electrolyte correction, and monitoring to ensure a safe and effective treatment process. 
                    <br />
                    <em>Note: by default, serum Na is checked q4h. More frequent Na checks requires additional orders at each interval. Urine chemistries will not be checked by default.</em></p>
                </div>
                <div className="instructions align-left">
                    <h3 className="gap-down align-left">Feedback</h3>
                    <p>After successfully completing the 24-hour simulation, you will receive feedback from our expert algorithm. This feedback will evaluate your decisions and provide insights into your management strategy. It's an invaluable opportunity to learn from your experience and refine your hyponatremia treatment skills.</p>
                    <br />
                    <p>Hyponatremia Simulator is designed to support your ongoing learning and professional development. Consider revisiting this simulation to further enhance your abilities and explore different treatment approaches.</p>
                </div>
            </div>
            <br />
            <div className="bottom-button-container">
            <button className="next-button bottom-button" onClick={handleClick} ><h4>RETURN TO SIMULATION</h4></button>
            </div>
            </div>
        </div>
    )
}