export const introduction = "Welcome to the world of advanced hyponatremia treatment training with Hyponatremia Simulator. Developed collaboratively by a registered nurse and a nephrologist, this innovative app offers an immersive, educational, and hands-on experience in managing hyponatremia cases within an acute care setting."

export const keyFeatures = {
    patientInteraction: "Immerse yourself in the shoes of a healthcare professional as you engage with lifelike virtual patients. Begin with an initial interview and conduct a comprehensive physical examination. This realistic scenario helps you hone your skills in recognizing hyponatremia symptoms and identifying potential underlying causes.",
    treatmentSimulation: "Take on the role of a clinical decision-maker in a dynamic hyponatremia treatment simulation. Receive detailed medical orders from the attending physician and make crucial decisions to regulate the patient's fluid volume and electrolyte balance. Experience the complexities and challenges of managing hyponatremia in a safe and controlled environment.",
    openAIIntegration: "Hyponatremia Simulator harnesses cutting-edge technology by seamlessly integrating OpenAI. Interact with AI-driven virtual patients, asking questions and receiving responses tailored to your unique queries. This immersive feature allows you to practice your patient communication skills and receive dynamic feedback, enhancing your overall patient care abilities.",
    scenarios: "Encounter a wide array of hyponatremia scenarios, each presenting its own set of challenges and complications. From underlying cause of of illness and from mild cases to severe situations, you'll have the opportunity to develop a comprehensive understanding of hyponatremia management, ensuring you're well-prepared for diverse clinical scenarios.",
    feedback: "Hyponatremia Simulator excels at evaluating your performance and providing constructive feedback. The app tracks your decision-making, patient interactions, and treatment outcomes, offering tailored recommendations for improvement. It's a dynamic learning tool that helps you enhance your skills in managing hyponatremia cases."
}

export const missionStatement = "At Hyponatremia Simulator, our mission is to empower healthcare professionals and students to excel in the complex realm of hyponatremia management. We are dedicated to providing an immersive and educational platform that fosters hands-on learning, clinical expertise, and effective patient communication. Our commitment is to offer a safe, controlled environment where users can develop and refine their skills in assessing and treating hyponatremia cases. We strive to continually innovate, integrating cutting-edge technology like OpenAI, and to ensure our users receive personalized feedback for ongoing improvement. Through our app, we aim to cultivate a community of confident and proficient healthcare providers who are well-prepared to deliver the highest standard of care to their patients."