
import SignUpForm from "../../components/SignUpForm/SignUpForm";
import { useState } from "react";
import LoginForm from "../../components/LoginForm/LoginForm";
import "./AuthPage.css"

export default function AuthPage ({ setUser }) {
    const [showSignUp, setShowSignUp] = useState(false)
    
    function handleAuthClick() {
        showSignUp ? setShowSignUp(false) : setShowSignUp(true)
    }

return (

    <main>
        <div className="auth-page-ctr flex-ctr-ctr-col">
            {/* <h1><em>Hyponatremia Simulator</em></h1> */}

            <div className="logo-ctr-auth">
                    <div>
                        <iframe className="iframe0" src="https://renalprimers.com/hyponatremia_simulation/hyponatremia_logo.html"></iframe>
                    </div>
                 </div>
           

            
            {showSignUp ?
            <SignUpForm setUser={setUser} />
            :
            <LoginForm setUser={setUser} />
            } 

        {showSignUp ? 
        <p>Already a user?</p>
        :
        <p>Don't have an account yet?</p>
        }
        <button className="auth-buttons" onClick={handleAuthClick}>{ showSignUp ? "Log In" : "Sign Up" }</button>

        </div>
    </main>

);
}