import { useParams, useNavigate, Link } from "react-router-dom";
import { getCase } from "../../../../utilities/cases-service";
import Loader from "../../../../components/Loader/Loader";
import { useState } from "react";
import "./PartOneInstructions.css"

export default function PartOneInstructions({simCase, simPart, setSimPart}) {
    function handleClick(evt) {
        setSimPart(simPart + 1)
    }
    return (
        <div className="part-one-instructions-ctr">
            <div className="banner ">
                <h2>P<small>ART </small> 1: I<small>NITIAL</small> E<small>VALUATION</small></h2>
            </div>

        <div className="content-below-header">

            <div className="instructions-ctr flex-ctr-ctr-col">
                <div className="instructions align-left">
                <h2 className="align-center">Simulation Instructions</h2>
                <br></br>
                    <p>Welcome to the Patient Interview, Examination, and Assessment section of Hyponatremia Simulator. In this immersive experience, you'll take on the role of a healthcare professional responsible for evaluating and managing a virtual patient with hyponatremia. This section is designed to enhance your clinical skills and decision-making abilities. Here's a step-by-step guide on how to navigate and make the most of this simulation:</p>
                </div>
                <div className="instructions align-left">
                    <h3 className="align-center">Patient History and Clinical Data</h3>
                    <br />
                    {/* <h4>Triage Note Review</h4>
                    <p> Start by reading the patient's triage note from the emergency department, which provides a brief overview of the case. This information sets the stage for your assessment.</p>
                    <br /> */}
                    <h4>Patient Interactions</h4>
                    <p>Use the input box to ask the virtual patient questions. Your inquiries will be sent to OpenAI to generate tailored responses based on the patient's condition and history. Engage in a meaningful dialogue to gather vital information.</p>
                    <br />
                    <h4>Review Additional Information</h4>
                    <p>On the left sidebar, you'll find menu items that allow you to access various sources of information, including pertinent medical history and home medications (Medical Record), physical exam, and labs values.
                        {/* <ul className="flex-ctr-ctr-col">
                            <li><strong>Medical records:</strong> review the patient's medical history, including prevoius illnesses, medications, and allergies</li>
                            <li><strong>Labs:</strong> examine the patient's laboratory results, such as blood tests, electrolyte levels, and more</li>
                            <li><strong>Physical examination findings:</strong> view the patient's patient head to toe assessment</li>
                        </ul> */}
                    </p>

                </div>
                <div className="instructions align-left">
                    <h3 className="align-center">Impression and Interventions</h3>
                    <br />
                    <h4>Initial Impression</h4>
                    <p>After gathering information, it's time to formulate your initial impression of the case. Based on the data you've collected, assess the patient's condition, and form your preliminary diagnosis or impressions.</p>
                    <br />
                    <h4>Diagnostic Interventions</h4>
                    <p>Create a list of diagnostic interventions you believe are necessary to confirm your initial impressions. Think about tests, imaging, or further assessments that will help you pinpoint the cause and severity of the hyponatremia.</p>
                    <br />
                    <h4>Therapeutic Interventions</h4>
                    <p>Similarly, compile a list of therapeutic interventions you would recommend based on your assessment. Consider treatments and interventions to address the patient's hyponatremia, keeping in mind their specific condition.</p>
                </div>
                <div className="instructions align-left">
                    <h3 className="align-center">Feedback</h3>
                    <br></br>
                    <p>Your initial impressions, diagnostic interventions, and therapeutic interventions will be processed by our expert backend data parsing system. This system will provide you with feedback and detailed explanations for your reasoning, helping you understand the clinical nuances and improve your decision-making skills.</p>
                </div>
            </div>
            <br></br>
            <div className="bottom-button-container">
                <button className="next-button bottom-button" onClick={handleClick} ><h4>Begin Simulation Part 1: Initial Evaluation</h4></button>
            </div>
            <br></br>
            <br></br>
            <br></br>
        </div>
    </div>
    )
}