import "./CasesIndexPage.css"
import { useState, useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import { Link } from "react-router-dom";
import { getCases } from "../../utilities/cases-service";
import CaseItem from "./CaseItem";
import { TfiRuler } from "react-icons/tfi";

export default function IndexPage () {
    const [cases, setCases] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    async function handleRequest() {
        const response = await getCases();
        if(typeof response === "object") {
            setCases(response)
            setIsLoading(false)
        } else {
            console.log("error: ", response)
        }
    }
    useEffect(() => {
        handleRequest();
      }, [])

    const casesList = cases?.map((simCase, idx) => < CaseItem simCase={simCase} idx={idx} handleRequest={handleRequest} />)

    return isLoading ? (
        <Loader />
      ) : (
        <div className="cases-index-ctr">
            <h1 className="banner">S<small>IMULATION </small> C<small>ASES</small></h1>

            {cases?.length ? 
            // {/* // USE THE BELOW IN FUTURE. ABOVE CODE FOR INITIAL USE */
            // {!cases.length ?  */
            <>
        <div className="content-below-header">
            <div className="new-case-div">
                <Link className="link" to="/new-case">
                    <button ><h3>BEGIN NEW SIMULATION</h3></button>
                </Link>
            </div>
            <div className="flex-ctr-ctr">
                <table className="index-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Patient Name</th>
                            <th>Primary Illness</th>
                            <th>Date Created</th>
                            <th>Case ID</th>
                            <th className="delete-cell"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {casesList}
                    </tbody>
                </table>
            </div>
            <br></br>
            </div>
            </>
            :
            <>
            <div className="content-below-header">
                <div className="new-case-div">
                    <Link className="link" to="/new-case">
                        <button><h3>BEGIN NEW SIMULATION</h3></button>
                    </Link>
                </div>
                <div className="new-case-div-text">
                <div>
                    <p>No prior simulations are linked to your account.</p>
                    <br></br> 
                    <br></br> 
                        <p><strong>Let's get started!</strong></p> 
                        </div>
                    
                </div>
            </div>
            </>
            }
        </div>
  );
}