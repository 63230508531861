import { useState } from 'react';
import { useParams } from 'react-router-dom';

export default function ChatLog({interactions, returnToSim, simCase, simPart}) {
    const { caseid } = useParams()

    const interactionsList = interactions?.map((i, idx) => 
    <div key={idx}>
        <div className="text-left-align">
        <h4>{i.question}</h4>
        </div>
        <div className="text-left-align">
        <p>{i.response}</p>
        </div>
    </div>)

    return (
        
        <div className="chat-log-ctr sim-modal">
            <h2>Conversation History</h2>
            <div className="text-left-align">
                <p>{simCase.initialOpenAIHistory}</p>
            </div>
            <div className="text-left-align">
               {interactions.length ? interactionsList : null}
            </div>
            <button className="return" onClick={returnToSim}>{simPart === 2 ? <h3>Return to Patient</h3> : <h3>Return to Feedback</h3>}</button>
        </div>
    )
}