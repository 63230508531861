import "../../SimulationPage.css"


export default function MedRecord({returnToSim, simCase, simPart}) {

    // const converter = {
    //     general: "General",
    //     HEENT: "HEENT",
    //     pulmonary: "Pulmonary",
    //     cardiac: "Cardiac",
    //     abdominal: "Abdominal",
    //     extremeties: "Extremeties",
    //     neuro: "Neurological"
    // }

    return (
        <div className="med-hx-ctr sim-modal">
            <h2>Medical Record</h2>

            <div className="gaps-space-evenly">
             <div className="justify-left text-left-align gaps">
                <p><strong>Name: </strong>{simCase.name}</p>
                <p><strong>Age: </strong>{simCase.age}</p>
                <p><strong>Sex: </strong>{simCase.sex}</p>
                <p><strong>Pronouns: </strong>{simCase.pronouns}</p>
             </div>

             </div>

             <div className="gaps-space-evenly">

             <div className="justify-left text-left-align gaps">
                <p><strong>Encounter: </strong>Emergency Dept.</p>
             </div>

                <div className="justify-left text-left-align gaps">
                <p><strong>Date: </strong>{new Date(simCase.createdAt).toDateString()}</p>
                </div>

             </div>
             

            <div>
                <br></br>
                <h4>Triage Nurse Intake Note</h4>
             <div className="justify-left text-left-align">
                <p>{simCase.initialOpenAIHPI}</p>
             </div>
            </div>

         <div className="medical-record-grid">
            <div className="medical-record-grid-1">
                <h3>Medical History</h3>
                <div className="justify-left text-left-align">
                <ul className="text-left-align">
                    {simCase.pastMedicalHistoryArray?.map((m, idx) => <li key={idx}>{m}</li>)}
                </ul>
                </div>
            </div>
            
            <div className="medical-record-grid-2">
                <h3>Home Medications</h3>
                <div className="justify-left text-left-align">
                <ul className="text-left-align">
                    {simCase.medicationsArray?.map((m, idx) => <li key={idx}>{m}</li>)}
                </ul>
                </div>
            </div>
        </div>
            <button className="return" onClick={returnToSim}>{simPart === 2 ? <h3>Return to Patient</h3> : <h3>Return to Feedback</h3>}</button>
        </div>
    )
}