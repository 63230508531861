export default function OrdersForm({setActiveWindow, timeInterval, ordersFormData, setOrdersFormData}) {

    function handleChange(evt) {
        const newData = {... ordersFormData, [evt.target.name]: evt.target.value}
        setOrdersFormData(newData)
    }

    function handleToggle(evt) {
        const newData = {... ordersFormData, [evt.target.name]: !ordersFormData[evt.target.name]}
        setOrdersFormData(newData)
    }

    console.log(ordersFormData)

    return (
        // <div className="orders-form-ctr flex-col-btw">
    <div className="orders-form-ctr flex-col-btw">
            <h4>Update Orders</h4>

    <form>

          <div className="top-and-bottom-orders-form">


 <div className="top-of-orders-form">

 <br></br>
 {/* <p className="orders-title"><strong>INFUSION ORDERS</strong></p> */}
 <br></br>
<div className="adjacent">

    <label>Normal Saline</label>
  <div>
  <p className="range-indicator">{ordersFormData.normalSalineIn} mL/h</p>
    <input type="range" name="normalSalineIn" min={0} max={1000} value={ordersFormData.normalSalineIn} onChange={handleChange} />
    <p className="range-indicator">&nbsp;&nbsp;&nbsp;<small>(0-1000 mL/h)</small></p>
  </div>
</div>
<div className="adjacent">
    <label >D5W</label>
  <div>
  <p className="range-indicator">{ordersFormData.d5wIn} mL/h</p>
    <input type="range" name="d5wIn" min={0} max={1000} value={ordersFormData.d5wIn} onChange={handleChange} />
    <p className="range-indicator">&nbsp;&nbsp;&nbsp;<small>(0-1000 mL/h)</small></p>
  </div>

</div>
<br></br>
<div className="adjacent">
    <label >3% Saline</label>
  <div>
  <p className="range-indicator">{ordersFormData.hypertonicSalineIn} mL/h</p>
  <input type="range" name="hypertonicSalineIn" min={0} max={200} value={ordersFormData.hypertonicSalineIn} onChange={handleChange} />
    <p className="range-indicator">&nbsp;&nbsp;&nbsp;<small>(0-200 mL/h)</small></p>
  </div>
</div>
    </div>  
           
   
   <div className="order-grouping bottom-of-orders-form">


    <div className="bottom-of-orders-form">

<br></br>
    {/* <p className="orders-title"><strong>THERAPEUTIC ORDERS</strong></p> */}


            <div className="adjacent">
                <label>Fluid Restriction</label>
                <input type="checkbox" name="fluidRestriction" value={ordersFormData.fluidRestriction} onChange={handleToggle} checked={ordersFormData.fluidRestriction} /> 
            </div>
            <br></br>
            <div className="adjacent">
                <label >Salt Tablets (2g x1)</label>
                <input type="checkbox" name="saltTabsGiven" value={ordersFormData.saltTabsGiven} onChange={handleToggle} checked={ordersFormData.saltTabsGiven} />   
            </div>
            <br></br>
            <div className="adjacent">
                <label >Urea Packets (15g x1)</label>
                <input type="checkbox" name="ureaGiven" value={ordersFormData.ureaGiven} onChange={handleToggle} checked={ordersFormData.ureaGiven} />   
            </div>
            {/* not yet added to backend ! */}
            <br></br>
            <div className="adjacent">
                <label >KCL (40 mEq capsule x1)</label>
                <input type="checkbox" name="potassiumGiven" value={ordersFormData.potassiumGiven} onChange={handleToggle} checked={ordersFormData.potassiumGiven} />   
            </div>
            <br></br>
            <div className="adjacent">
                <label >Furosemide (x1 dose)</label>
                <input type="checkbox" name="furosemideGiven" value={ordersFormData.furosemideGiven} onChange={handleToggle} checked={ordersFormData.furosemideGiven} />   
            </div> 
            <br></br>
            <div className="adjacent">
                <label >ddAVP (x1 dose)</label>
                <input type="checkbox" name="ddAVPGiven" value={ordersFormData.ddAVPGiven} onChange={handleToggle} checked={ordersFormData.ddAVPGiven} />   
            </div> 
            <br></br>
            <div className="adjacent">
                <label >Tolvaptan (x1 dose)</label>
                <input type="checkbox" name="tolvaptanGiven" value={ordersFormData.tolvaptanGiven} onChange={handleToggle} checked={ordersFormData.tolvaptanGiven} />   
            </div> 
            <br></br>

        </div>

        <div className="bottom-of-orders-form">

        {/* <p className="orders-title-2"><strong>DIAGNOSTIC ORDERS</strong></p> */}
 
        <br></br>
            <div className="adjacent">
                <label><sup>*&nbsp;</sup>Serum Na x1</label>
                <input type="checkbox" name="serumNaOrderNext" value={ordersFormData.serumNaOrderNext} onChange={handleToggle} checked={ordersFormData.serumNaOrderNext} />   
            </div>
            <br></br>
            <div className="adjacent">
                <label><sup>*&nbsp;</sup>Serum K x1</label>
                <input type="checkbox" name="serumKOrderNext" value={ordersFormData.serumKOrderNext} onChange={handleToggle} checked={ordersFormData.serumKOrderNext} />   
            </div>
            <br></br>
            <div className="adjacent">
                <label >Urine Osm x1</label>
                <input type="checkbox" name="urineOsmOrderNext" value={ordersFormData.urineOsmOrderNext} onChange={handleToggle} checked={ordersFormData.urineOsmOrderNext} />   
            </div>
            <br></br>
            <div className="adjacent">
                <label >Urine Na x1</label>
                <input type="checkbox" name="urineNaOrderNext" value={ordersFormData.urineNaOrderNext} onChange={handleToggle} checked={ordersFormData.urineNaOrderNext} />   
            </div>
            <br></br>
            <div className="adjacent">
                <label >Urine K x1</label>
                <input type="checkbox" name="urineKOrderNext" value={ordersFormData.urineKOrderNext} onChange={handleToggle} checked={ordersFormData.urineKOrderNext} />   
            </div>

            <br></br>
            <br></br>
                <div className="adjacent q4-explanation">
                    <p className="align-right star">*&nbsp;</p>
                    <label className="align-left">serum Na and K will result q4 hrs by default</label>
                </div>

                </div>
            </div>
            </div>


            
            </form>
            <div></div>
            {/* <button onClick={() => setActiveWindow("Data")}>Close Form</button> */}
        </div>
    )
}