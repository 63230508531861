import { useState } from 'react';
import { useParams } from 'react-router-dom';
// import { chatOpenAI } from '../../../../utilities/cases-service';
// import Loader from '../../../../components/Loader/Loader';
import Interview from './Interview';
import { ImBubbles3 } from "react-icons/im"
import { BsClipboardPulse } from "react-icons/bs"
import { BiTestTube } from "react-icons/bi"
import { PiStethoscopeDuotone } from "react-icons/pi"
import { TfiThought } from "react-icons/tfi"
import "./PartOneSim.css"
import ChatLog from './ChatLog';
import MedRecord from './MedRecord';
import Labs from './Labs';
import PhysExam from './PhysExam';
import ImpressionPlan from './ImpressionPlan';
import Instructions from './Instructions';


export default function PartOneSim({simCase, simPart, setSimPart}) {

    const { caseid } = useParams()
    const [interactions, setInteractions] = useState([])
    const [hideToggledWindow, setHideToggledWindow] = useState(true)
    const [hideMainWindows, setHideMainWindows] = useState(false)
    const [showChatLog, setShowChatLog] = useState(false)
    const [activeComponent, setActiveComponent] = useState(null)

    // function handleClick(evt) {
    //     setSimPart(simPart += 1)
    // }

    function returnToSim() {
        setHideMainWindows(false)
        setHideToggledWindow(true)
    }

    function handleSideClick(evt) {
        setHideMainWindows(true)
        setHideToggledWindow(false)
        setActiveComponent(evt.currentTarget.value)
        console.log(evt.currentTarget.value)
        console.log(hideMainWindows, hideToggledWindow, showChatLog)
        // setHideMainWindows(!hideMainWindows)
        // setHideToggledWindow(!hideToggledWindow)
        // setActiveComponent(evt.currentTarget.value)
        // console.log(evt.currentTarget.value)
        // console.log(hideMainWindows, hideToggledWindow, showChatLog)
    }

    console.log(simCase)
    console.log(simCase.currentClinState)


    return (
        
        <div className="part-one-sim flex-ctr-ctr">
            <div className="sim-grid">
                <div className="side-bar flex-col-even">
                  <div className="button-gaps">
                    <div className="flex-ctr-ctr-col">
                        <button onClick={handleSideClick} value="ChatLog">
                            <ImBubbles3 className="icon" />
                        </button>
                        <p className="gap-above"><em><strong>Chat History</strong></em></p>
                    </div>
                  </div>
                  <div className="button-gaps">
                    <div className="flex-ctr-ctr-col">
                        <button onClick={handleSideClick} value="MedRecord">
                            <BsClipboardPulse className="icon"/>
                        </button>
                        <p className="gap-above"><em><strong>Medical Record</strong></em></p>
                    </div>
                  </div>
                  <div className="button-gaps">
                    <div className="flex-ctr-ctr-col">
                        <button onClick={handleSideClick} value="Labs">
                            <BiTestTube className="icon"/>
                        </button>
                        <p className="gap-above"><em><strong>Lab Values</strong></em></p>
                    </div>
                    </div>
                    <div className="button-gaps">
                    <div className="flex-ctr-ctr-col">
                        <button onClick={handleSideClick} value="PhysExam">
                            <PiStethoscopeDuotone className="icon"/>
                        </button>
                        <p className="gap-above"><em><strong>Physical Exam</strong></em></p>
                    </div>
                    </div>

                    <div className="logo-ctr">
                    <div>
                        <iframe className="iframe2" src="https://renalprimers.com/hyponatremia_simulation/hyponatremia_logo_part_1.html"></iframe>
                    </div>
                 </div>

                </div>
                <div className={`toggled-window flex overflow-auto ${hideToggledWindow ? "hidden": "" }`} >
                    <div className={`flex ${activeComponent === "ChatLog" ? "" : "hidden"}`}> 
                        <ChatLog interactions={interactions} returnToSim={returnToSim} simCase={simCase} simPart={simPart} />
                    </div>
                    <div className={`flex ${activeComponent === "MedRecord" ? "" : "hidden"}`}>
                        <MedRecord returnToSim={returnToSim} simCase={simCase} simPart={simPart} />
                    </div>
                    <div className={`flex ${activeComponent === "Labs" ? "" : "hidden"}`}>
                        <Labs returnToSim={returnToSim} simCase={simCase} simPart={simPart} />
                    </div>
                    <div className={`flex ${activeComponent === "PhysExam" ? "" : "hidden"}`}>
                        <PhysExam returnToSim={returnToSim} simCase={simCase} simPart={simPart} />
                    </div>
                    <div className={`flex ${activeComponent === "ImpressionPlan" ? "" : "hidden"}`}>
                        <ImpressionPlan returnToSim={returnToSim} simCase={simCase} simPart={simPart} setSimPart={setSimPart} />
                    </div>
                    <div className={`flex ${activeComponent === "Instructions" ? "" : "hidden"}`}>
                        <Instructions returnToSim={returnToSim} simCase={simCase} simPart={simPart} setSimPart={setSimPart} />
                    </div>
                </div>
                <div className={`flex-box flex ${hideMainWindows ? "hidden" : ""}`}>
                    <Interview simCase={simCase} interactions={interactions} setInteractions={setInteractions} />
                </div>
                <div className={`sim-window flex-ctr-ctr ${hideMainWindows ? "hidden": ""}`} >
                    <iframe className="iframe" src={simCase.sex === "male" ? "https://robscontent.com/hyponatremia_simulation/hyponatremia_avatar.html" : "https://renalprimers.com/hyponatremia_simulation/female_hyponatremia_avatar.html" }></iframe>
                </div>


                <div className="bottom-bar">

                <div className="bottom-bar-buttons flex-ctr-ctr">
        
                <div className="right-gap">

                    <button onClick={handleSideClick} value="Instructions" className="toolbar">
                        {/* <TfiThought className="icon"/>  */}
                        <h4>Review Instructions</h4>
                    </button>
                </div>

                    {/* <button onClick={handleSideClick} value="openAIandPatient" className="toolbar">
                        About AI-integration
                    </button> */}

                    {/* <button className="next-button" onClick={handleClick} >Next</button> */}
                <div className="right-gap">  
                    <button onClick={handleSideClick} value="ImpressionPlan" className="toolbar">
                        <TfiThought className="icon"/> <h4> Impression & Plan</h4>
                    </button>

                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

