import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { chatOpenAI } from '../../../../utilities/cases-service';
import Loader from '../../../../components/Loader/Loader';

import "../../SimulationPage.css"


export default function Interview({simCase, interactions, setInteractions }) {
    const { caseid } = useParams()

    const [formData, setFormData] = useState({question: ""})
    // const [response, setResponse] = useState(null)
    const [disabled, setDisabled] = useState(false)
    const [showOpenAILoader, setShowOpenAILoader] = useState(false)
    const [showRecentInteraction, setShowRecentInteraction] = useState(true)

    function handleChange(evt) {
        const newData = {... formData, [evt.target.name]: evt.target.value}
        setFormData(newData)
    }

    async function handleSubmit(evt) {
        evt.preventDefault()
        try {
            setDisabled(true)
            setShowOpenAILoader(true)
            setShowRecentInteraction(false)
            const res = await chatOpenAI(caseid, formData)
            const prompt = {question: formData.question, response: res}
            console.log(res)
            setFormData({question: ""})
            setInteractions([prompt, ... interactions])
            setDisabled(false)
            setShowOpenAILoader(false)
            setShowRecentInteraction(true)
        }catch (error) {
            console.log(error)
        }
    }

    const initial = showOpenAILoader ? null : simCase.initialOpenAIHistory

    return (
        
        <div className="interview-ctr">
            {/* <h2>Perform your history assessment</h2> */}
            <form className="chatOpenAI-form" autoComplete="off" onSubmit={handleSubmit} htmlFor="question">
              <div className="gaps gap-down">
                <p className="flex-ctr-ctr align-left"><strong> Ask the patient a question: </strong></p>
                <input type="text" name="question" id="question" value={formData.question} onChange={handleChange}/>
                <input type="submit" value="Ask question" disabled={disabled} placeholder='write a question'/>
              </div>
            </form>
            <div className="interactions flex-ctr-ctr-col">
                {interactions.length && showRecentInteraction ? 
                    <>
                    <div className="gap-down">
                        <div>
                          <h4>{interactions[0].question}</h4>
                        </div>
                     </div>
                       <div className="align-left">
                        <p>{interactions[0].response}</p>
                      </div>
                    
                    </>
                :
                    <p>{initial}</p>
                }
                {showOpenAILoader ? <Loader /> : null}
            </div>

        </div>
    )
}