import Data from "./Data"
import OrdersForm from "./OrdersForm"
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { sendOrders } from "../../../../utilities/cases-service"
import "./PartTwoSim.css"
import ActiveOrders from "./ActiveOrders"
import Model from "./Model"
import Clock from "./Clock"

export default function PartTwoSim({simCase, setSimCase, simPart, setSimPart}) {
    const { caseid } = useParams()
    const [activeWindow, setActiveWindow] = useState("Data")
    const [timeInterval, setTimeInterval] = useState(1)
    const [disabled, setDisabled] = useState(false)
    const [currentCount, setCount] = useState(30);

    const [ordersFormData, setOrdersFormData] = useState(
        {
            timeInterval: null,
            fluidRestriction: false, // restricted to 1.5L/day or NOT restricted at all is false
            normalSalineIn: 0, // rate per hour
            d5wIn: 0, // rate per hour
            hypertonicSalineIn: 0, // rate per hour
            saltTabsGiven: false,
            potassiumGiven: false,
            furosemideGiven: false,
            ddAVPGiven: false,
            tolvaptanGiven: false,
            ureaGiven: false,
            serumNaOrderNext: false, //default is every 4 hours; this allows you to order EXTRA sodium checks
            serumKOrderNext: false, // default is every 4 hours; done same way as for sodium
            urineOsmOrderNext: false, //order this for next tiem interval as needed
            urineNaOrderNext: false, //order this for next tiem interval as needed
            urineKOrderNext: false //order this for next tiem interval as needed
          }
    )

    const uncheckedBoxes = {
        // not fluid restriction, since this stays on once ordered
        saltTabsGiven: false,
        potassiumGiven: false,
        furosemideGiven: false,
        ddAVPGiven: false,
        tolvaptanGiven: false,
        ureaGiven: false,
        serumNaOrderNext: false, //default is every 4 hours; this allows you to order EXTRA sodium checks
        serumKOrderNext: false, // default is every 4 hours; done same way as for sodium
        urineOsmOrderNext: false, //order this for next tiem interval as needed
        urineNaOrderNext: false, //order this for next tiem interval as needed
        urineKOrderNext: false //order this for next tiem interval as needed
    }

    function handleClick(evt) {
        setSimPart(simPart + 1)
    }

    async function handleNextInterval(evt) {
        if (timeInterval < 24) {
            try {
                setDisabled(true)
                const finalData = {... ordersFormData, timeInterval }
                // console.log("finaldata", finalData)
                const response = await sendOrders(caseid, finalData)
                // console.log("response", response)
                setSimCase(response)
                // console.log(typeof(response))
                setTimeInterval(timeInterval + 1)
                setOrdersFormData(Object.assign(ordersFormData, uncheckedBoxes))
                setActiveWindow("Data")
                setDisabled(false)
                setCount(30)
            } catch(error) {
                console.log(error)
            }
        } else if (timeInterval === 24) {
            try {
                setDisabled(true)
                const finalData = {... ordersFormData, timeInterval }
                // console.log("finaldata", finalData)
                const response = await sendOrders(caseid, finalData)
                // console.log("response", response)
                setSimCase(response)
                // console.log(typeof(response))
                setTimeInterval(timeInterval + 1)
                setOrdersFormData(Object.assign(ordersFormData, uncheckedBoxes))
                setActiveWindow("Data")
                setDisabled(false)
                setCount(99)
            } catch(error) {
                console.log(error)
            }
        } else {
            setSimPart(simPart + 1)
        }
    }
    // const intervalTimer = setInterval(handleNextInterval, 10000)
    
    console.log("timeinterval:" , timeInterval)
    // console.log("simCase:" , simCase)

    return (
        <div className="part-two-sim-ctr">


            <div className="width-too-small">
                <br></br>
                <h5>Simulation requires screen width of at least 680px</h5>
                <br></br>
                <h5>Please increase width of your browser window</h5>
            </div>

            <div className="sim2-grid">

            {/* <div className="upper-box"></div> */}

                {/* <div className="toggle-window flex overflow-auto"> */}

                <div className="toggle-window">

                    <div className={`data flex ${activeWindow === "Data" ? null : "hidden"}`}>
                        
                        
                        {/* <Data simCase={simCase}/> */}

                    <div className="data-table-outer-container-in-sim overflow-auto">
                            <div className="overflow-auto">
                                <Data simCase={simCase}/>
                            </div>
                    </div>

                    </div>

                <div className="lower-box"></div>


                <div className={`orders-form flex ${activeWindow === "OrdersForm" ? null : "hidden"}`}>
                        <OrdersForm timeInterval={timeInterval} ordersFormData={ordersFormData} setOrdersFormData={setOrdersFormData} />
                </div>
                </div>
                <div className="orders flex overflow-auto">
                    <ActiveOrders simCase={simCase} ordersFormData={ordersFormData} />
                
                </div>
            
    
                <div className="model">
                    <Model simCase={simCase} />
                </div>

                <div className="avatar flex-col-btw">
                    <div className="flex">
                        <h4>Patient</h4>
                    </div>
                        <iframe className="iframe flex" src={simCase?.sex === "male" ? "https://robscontent.com/hyponatremia_simulation/hyponatremia_avatar.html" : "https://renalprimers.com/hyponatremia_simulation/female_hyponatremia_avatar.html" }></iframe>
                    <div></div>
                </div>

            
    
                <div className="bottom-nav">

                <div className="flex">

                <div className="logo-ctr-2">
                        <iframe className="iframe2-again" src="https://renalprimers.com/hyponatremia_simulation/hyponatremia_logo_part_2.html"></iframe>
                </div>

                <div className="space-evenly">

                  <div className="button-gaps2">
                    <button onClick={(evt) => setActiveWindow(activeWindow === "OrdersForm" ? "Data" : "OrdersForm")} className={`toolbar ${timeInterval === 25 ? "hidden" : ""}`}>{activeWindow === "OrdersForm" ? "View Data" : "Update Orders"}</button>
                  </div>
                  <div className="button-gaps2">
                    <button onClick={handleNextInterval} disabled={disabled} className="toolbar" >{timeInterval === 25 ? "See Final Feedback" : "Go to Next Interval" }</button>
                  </div>
                  <div className="button-gaps2">
                    {/* <h5>Next Interval in -</h5> */}
                    <h5>{timeInterval >= 25 ? "Redirecting in -" : "Next Interval in -"}</h5>
                    <div className="clock-size">
                    <Clock handleNextInterval={handleNextInterval} timeInterval={timeInterval} currentCount={currentCount} setCount={setCount} />
                    </div>
                    <h5>- seconds </h5>
                </div>
         
                </div>
                </div>
                
                </div>
            </div>
            {/* <button onClick={handleClick} >Next</button> */}
            {/* <button onClick={(evt)=> setTimeInterval(timeInterval + 1)}>Increment time</button> */}

        {/* for testing purposes: displaying current clinical data: */}
    
            <div className="show-current-tonicity-values">
                <p>Serum Na: {simCase.clinCurrentState.serumSodium}</p>
                <p>Urine Osm: {simCase.clinCurrentState.urineOsm}</p>
                <p>Urine Na: {simCase.clinCurrentState.urineSodium}</p>
                <p>Urine K: {simCase.clinCurrentState.urinePotassium}</p>
            </div>

            <div className="show-current-tonicity-values">
                <p>ADH Activity Var: {simCase.clinCurrentState.activityADH.active} for {simCase.clinCurrentState.activityADH.hoursLeft} intervals</p>
                <p>Naturesis Activity Var: {simCase.clinCurrentState.activityNaturesis.active} for {simCase.clinCurrentState.activityNaturesis.hoursLeft} intervals</p>
                <p>Hourly Solute In: {simCase.clinCurrentState.hourlySoluteIn}</p>
                <p>Hourly Solute Out: {simCase.clinCurrentState.hourlySoluteOut}</p>
                <p>Total Body Solute: {simCase.clinCurrentState.totalBodySolute}</p>
            </div>

        </div>
    )
}