import { useState } from "react"
import { useNavigate } from "react-router-dom"
import "./UserEditPage.css"
import { updateUser, logOut } from "../../utilities/users-service"

export default function UserEditPage({user, setUser}) {
    
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        name: user.name,
        email: user.email,
        confirm: "",
        hideTimer: user.hideTimer,
        showIllnessScripts: user.showIllnessScripts
    })

    function handleChange(evt) {
        const newData = {... formData, [evt.target.name]: evt.target.value}
        setFormData(newData)
    }

    function handleToggle(evt) {
        const newData = {... formData, [evt.target.name]: !formData[evt.target.name]}
        setFormData(newData)
    }

    async function handleSubmit(evt) {
        try {
            evt.preventDefault()
            // const { name, email } = formData
            // const newUserData = {name, email}
            await updateUser(user._id, formData)
            logOut()
            setUser(null)
            navigate("/")

        }catch (error) {
            console.log(error)
        }
    }

    const disable = formData.email !== formData.confirm

    return (
        <div className="edit-user-ctr">
            <div className="banner">
                <h1>E<small>DIT</small> P<small>ROFILE</small></h1>
            </div>
        <div className="content-below-header">
            <div className="flex-ctr-ctr">
              <form className="edit-user-form"autoComplete="off" onSubmit={handleSubmit}>
                <label>Name:</label>
                <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                <label>Email:</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                <label>Confirm Email:</label>
                <input type="email" name="confirm" value={formData.confirm} onChange={handleChange} required />
                <div></div>

                <div className="align-left">

                <br></br>
                    
                    <input type="checkbox" name="hideTimer" value={formData.hideTimer} onChange={handleToggle} checked={formData.hideTimer} /> 
                    <label > <small>Deactivate Timer for Part II (30 second countdown / hour interval)</small> </label>

                <div></div>
                <br></br>
                   
                    <input type="checkbox" name="showIllnessScripts" value={formData.showIllnessScripts} onChange={handleToggle} checked={formData.showIllnessScripts} /> 
                    <label > <small>Set illness script when creating new case (Polydipsia, Hypovolemia, SIADH)</small> </label>
                </div>
                
            
                <div></div>
             

                <br></br>
                <div></div>
                <h6><span>- you will be required to log in again after updating your settings -</span></h6>
                <div></div>
                <button col-span="2" type="submit" disabled={disable}><h3>Confirm Email and Update Settings</h3></button>

              </form>
            </div>
          </div>
          </div>
    )
}