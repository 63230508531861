import { Link } from "react-router-dom";
import * as userService from "../../utilities/users-service"
import "./NavBar.css"


export default function NavBar ({ user, setUser }) {
    function handleLogOut() {
        userService.logOut()
        setUser(null)
    }

    return (

        <nav>
             <Link className="link-nav-bar" to="/">
                <button className="nav-link-button"><h4>HOME</h4></button>
            </Link>
            { user !== null ? 
                <>
                    <Link className="link-nav-bar" to="/user">
                        <button className="nav-link-button profile-button"><h4>SETTINGS</h4></button>
                    </Link>
                    <Link className="link-nav-bar" to="/cases">
                        <button className="nav-link-button profile-button"><h4>CASES</h4></button>
                    </Link>
                    {/* <span><strong>Welcome, {user?.name}</strong> </span> */}
                    <Link className="link-nav-bar logout" to="/" onClick={handleLogOut}>
                        <button className="nav-link-button"><span><p> {user?.name} </p></span><p>&nbsp; | &nbsp; </p><h4> LOG OUT </h4></button>
                    </Link>
                </>
                :
                null 
            }

        </nav>
    );
}

