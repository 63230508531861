import "./PartOneFeedback.css"

export default function Feedback({simCase}) {
    const ourDiagnosticsList = simCase.ourAnswerDiagnostics?.map((d, idx) => <li key={idx}>{d}</li>)
    const userDiagnosticsList = simCase.userDiagnostics?.map((e, idx) => <li key={idx} >{e}</li>)
    const ourTherapeuticsList = simCase.ourAnswerTherapeutics?.map((f, idx) => <li key={idx} >{f}</li>)
    const userTherapeuticsList = simCase.userTherapeutics?.map((g, idx) => <li key={idx}>{g}</li> )
    return (
        <div className="feedback-ctr sim-modal flex-col-btw">
            <h2>Part 1 Feedback</h2>
        <div className="comparison-table-div">
            <div className="comparison-table">
                <h3>Compare Impressions</h3>
                <table>
                    <tr className="feedback-table-section">
                        <th><h5>Your Impression</h5></th>
                        <td >{simCase.userImpression}</td>
                    </tr>
                    <tr className="feedback-table-section">
                        <th><h5>Our Impression</h5></th>
                        <td>{simCase.ourAnswerImpression}</td>
                    </tr>
                </table>
            </div>
            <br></br>
            <div className="comparison-table">
                <h3>Compare Diagnostics</h3>
                <table>
                    <tr>
                    <th><h5>Your Plan</h5></th>
                        <td>{userDiagnosticsList}</td>
                    </tr>
                    <tr>
                    <th><h5>Our Plan</h5></th>
                        <td>{ourDiagnosticsList}</td>
                    </tr>
                </table>
            </div>

            <div className="comparison-table">
            <br></br>
                <h3>Compare Therapeutics</h3>
                <table>
                    <tr>
                    <th><h5>Your Plan</h5></th>
                        <td>{userTherapeuticsList}</td>
                    </tr>
                    <tr>
                    <th><h5>Our Plan</h5></th>
                        <td>{ourTherapeuticsList}</td>
                    </tr>
                </table>
            </div>
            </div>

            <br></br>


            {/* <h2>Feedback</h2>
            <div className="impression-comparison">
                <h3>Initial Impression</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Your Impression</th>
                            <th>Our Impression</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {simCase.userImpression}
                            </td>
                            <td>
                                {simCase.ourAnswerImpression}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="diagnostics-comparison">
                <h3>Diagnostics</h3>
                <table >
                    <thead>
                        <tr >
                            <th>Your Diagnostics</th>
                            <th>Our Diagnostics</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr>
                            <td>
                                {userDiagnosticsList}
                            </td>
                            <td>
                                {ourDiagnosticsList}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="therapeutics-comparison">
                <h3>Therapeutics</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Your Therapeutics</th>
                            <th>Our Therapeutics</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {userTherapeuticsList}
                            </td>
                            <td>
                                {ourTherapeuticsList}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> */}
        </div>
    )
}