import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ImBubbles3 } from "react-icons/im"
import { BsClipboardPulse } from "react-icons/bs"
import { BiTestTube } from "react-icons/bi"
import { PiStethoscopeDuotone } from "react-icons/pi"
import ChatLog from '../PartOneSim/ChatLog';
import MedRecord from '../PartOneSim/MedRecord';
import Labs from '../PartOneSim/Labs';
import PhysExam from '../PartOneSim/PhysExam';
import Feedback from './Feedback';
import "./PartOneFeedback.css"



export default function PartOneFeedback({simCase, simPart, setSimPart}) {

    const { caseid } = useParams()
    const [interactions, setInteractions] = useState([])
    const [hideToggledWindow, setHideToggledWindow] = useState(true)
    const [hideMainWindows, setHideMainWindows] = useState(false)
    const [showChatLog, setShowChatLog] = useState(false)
    const [activeComponent, setActiveComponent] = useState(null)

    function handleClick(evt) {
        setSimPart(simPart + 1)
    }

    function returnToSim() {
        setHideMainWindows(false)
        setHideToggledWindow(true)
    }

    function handleSideClick(evt) {
        setHideMainWindows(true)
        setHideToggledWindow(false)
        setActiveComponent(evt.currentTarget.value)
        console.log(evt.currentTarget.value)
        console.log(hideMainWindows, hideToggledWindow, showChatLog)
        // setHideMainWindows(!hideMainWindows)
        // setHideToggledWindow(!hideToggledWindow)
        // setActiveComponent(evt.currentTarget.value)
        // console.log(evt.currentTarget.value)
        // console.log(hideMainWindows, hideToggledWindow, showChatLog)
    }


    return (
        
        <div className="part-one-sim flex-ctr-ctr">
            <div className="sim-grid">
                <div className="side-bar flex-col-even">
                <div className="button-gaps">
                    <div className="flex-ctr-ctr-col">
                        <button onClick={handleSideClick} value="ChatLog">
                            <ImBubbles3 className="icon" />
                        </button>
                        <p className="gap-above"><em><strong>Chat History</strong></em></p>
                    </div>
                </div>
                <div className="button-gaps">
                    <div className="flex-ctr-ctr-col">
                        <button onClick={handleSideClick} value="MedRecord">
                            <BsClipboardPulse className="icon"/>
                        </button>
                        <p className="gap-above"><em><strong>Medical Record</strong></em></p>
                    </div>
                </div>
                <div className="button-gaps">
                    <div className="flex-ctr-ctr-col">
                        <button onClick={handleSideClick} value="Labs">
                            <BiTestTube className="icon"/>
                        </button>
                        <p className="gap-above"><em><strong>Lab Values</strong></em></p>
                    </div>
                </div>
                <div className="button-gaps">
                    <div className="flex-ctr-ctr-col">
                        <button onClick={handleSideClick} value="PhysExam">
                            <PiStethoscopeDuotone className="icon"/>
                        </button>
                        <p className="gap-above"><em><strong>Physical Exam</strong></em></p>
                    </div>
                </div>
                    <div className="logo-ctr">
                    <div>
                        <iframe className="iframe2" src="https://renalprimers.com/hyponatremia_simulation/hyponatremia_logo_part_1.html"></iframe>
                    </div>
                 </div>


                </div>
                <div className={`toggled-window flex overflow-auto ${hideToggledWindow ? "hidden": "" }`} >
                    <div className={`flex ${activeComponent === "ChatLog" ? "" : "hidden"}`}> 
                        <ChatLog interactions={interactions} returnToSim={returnToSim} simCase={simCase} simPart={simPart} />
                    </div>
                    <div className={`flex overflow-auto ${activeComponent === "MedRecord" ? "" : "hidden"}`}>
                        <MedRecord returnToSim={returnToSim} simCase={simCase} simPart={simPart} />
                    </div>
                    <div className={`flex overflow-auto ${activeComponent === "Labs" ? "" : "hidden"}`}>
                        <Labs returnToSim={returnToSim} simCase={simCase} simPart={simPart} />
                    </div>
                    <div className={`flex overflow-auto ${activeComponent === "PhysExam" ? "" : "hidden"}`}>
                        <PhysExam returnToSim={returnToSim} simCase={simCase} simPart={simPart} />
                    </div>

                </div>
                <div className={`feedback flex overflow-auto ${hideMainWindows ? "hidden": ""}`} >
                    <Feedback simCase={simCase} />
                </div>


                {/* <div className="logo-ctr">
                    <p>logo</p>
                </div> */}

                
                <div className="bottom-bar flex-ctr-ctr">

        
                  <div className="side-gaps">
                    <button className="next-button toolbar" onClick={handleClick} ><h4>Proceed to Part 2:  Management</h4></button>
                </div>
                </div>
            </div>
        </div>
    )
}