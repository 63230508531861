import { useState } from 'react';
import * as usersService from "../../utilities/users-service"
import { BsFillPersonFill } from "react-icons/bs"
import { signUp } from '../../utilities/users-service'
import Loader from "../Loader/Loader"
import "./LoginForm.css"

export default function LoginForm({ setUser }) {
  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false)

  function handleChange(evt) {
    setCredentials({ ...credentials, [evt.target.name]: evt.target.value });
    setError('');
  }

  async function handleSubmit(evt) {
    // Prevent form from being submitted to the server
    evt.preventDefault();
    try {
      setIsLoading(true)
      // The promise returned by the signUp service method 
      // will resolve to the user object included in the
      // payload of the JSON Web Token (JWT)
      const user = await usersService.login(credentials);
      setUser(user);
      setIsLoading(false)
    } catch {
      setError('Log In Failed - Try Again');
      setIsLoading(false)
    }
  }

  async function handleGuestLogin(evt) {
    evt.preventDefault()
    try {
      setIsLoading(true)
      const user = await usersService.signUp({
        name: "Guest Account",
        email: `${Date.now() % 1000000}@guest`,
        password: Date.now() % 1000000
      })
      setUser(user);
      setIsLoading(false)
    } catch(error) {
      console.log(error)
      setError('Log In Failed - Try Again');
      setIsLoading(false)
    }
  }

  return (
    <div>
            <br></br>
      <h2>Login</h2>
      <div className="login-ctr space-left-form2">
        <form className="login-form" autoComplete="off" onSubmit={handleSubmit}>
          <label>Email:</label>
          <input type="text" name="email" value={credentials.email} onChange={handleChange} required />
          <label>Password:</label>
          <input type="password" name="password" value={credentials.password} onChange={handleChange} required />
          <div></div>
          <button type="submit" disabled={isLoading}>LOG IN</button>

          <div></div>
            {/* <label >Want to test the app?</label> */}
            <button className='guest-login flex-ctr-ctr' onClick={handleGuestLogin} disabled={isLoading}><BsFillPersonFill />LOG IN AS GUEST</button>
          <div></div>
          {isLoading ? <Loader /> : null}
        </form>
      </div>
      <p className="error-message">&nbsp;{error}</p>
    </div>
  );
}