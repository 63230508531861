
import { useState, useEffect } from "react";
import { createCase, getMostRecentCase } from "../../utilities/cases-service"
import { useNavigate } from "react-router-dom";
import "./NewCasePage.css"

export default function NewCasePage({user}) {
    // adding user here to access settings

    const navigate = useNavigate()


    // add random illness script selector here:

        let randomIllnessScriptValue = ""

        let randomIllnessScriptNumber = randomIllnessScriptNumberFunction(1, 5) 

        function randomIllnessScriptNumberFunction (min, max) {
            return Math.floor(Math.random() * (max - min + 1) ) + min;
        }

        if (randomIllnessScriptNumber === 1) {
            randomIllnessScriptValue = "polydipsia"
        }

        if (randomIllnessScriptNumber === 2) {
            randomIllnessScriptValue = "hypoperfusion"
        }

        if (randomIllnessScriptNumber === 3) {
            randomIllnessScriptValue = "hypoperfusion"
        }

        if (randomIllnessScriptNumber === 4) {
            randomIllnessScriptValue = "SIADH"
        }


        if (randomIllnessScriptNumber === 5) {
            randomIllnessScriptValue = "SIADH"
        }


    //    


    const [formData, setFormData] = useState({sex: "male", primaryIllnessScript: randomIllnessScriptValue})

    async function handleSubmit(evt) {
        evt.preventDefault()
        // console.log(evt.target.value)
        await createCase(formData)
        const newCase = await getMostRecentCase()
        console.log(newCase)
        navigate(`/cases/${newCase._id}`)
        // navigate(`/`)
    }

    function handleChange(evt) {
        const newData = {... formData, [evt.target.name]: evt.target.value}
        setFormData(newData)
    }
    
    console.log(formData)
    
    
    return (
        <div className="new-case-page-ctr">

        <div className="banner">
            <h1>C<small>REATE</small> V<small>IRTUAL </small> P<small>ATIENT</small></h1>
        </div> 
        
        <div className="content-below-header">
            {/* <h2>Select the underlying cause of hyponatemia</h2> */}
            <form onSubmit={handleSubmit} className="new-case-form">
                <label>* Patient's name:</label>
                <input type="text" name="name" value={formData.name} onChange={handleChange} required/>
                <label>* Biological Sex:</label>
                <select name="sex" required onChange={handleChange}>
                    {/* <option disabled selected>select sex</option> */}
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                </select>
                <label>Pronouns:</label>
                <input type="text" name="pronouns" value={formData.pronouns} defaultValue={"not specified"} onChange={handleChange}/>
               


                {user.showIllnessScripts

                ?
                <>
                    <label>* Select Primary Etiology</label>
                    <select name="primaryIllnessScript" required onChange={handleChange}>
                        <option value=""></option>
                        <option value="polydipsia">Polydipsia</option>
                        <option value="hypoperfusion">Hypoperfusion</option>
                        <option value="SIADH">SIADH</option>
                    </select>
                </>
                : 
                null
                }

                <div></div>
                <div></div>
                <div></div>
                <button type="submit"><h4>START SIMULATION</h4></button>
            </form>
            {/* <div className="primary-illness-selection-ctr">
                <input type="button" value="polydipsia" onClick={handleClick} />
                <input type="button" value="hypoperfusion" onClick={handleClick} />
                <input type="button" value="SIADH" onClick={handleClick} />
            </div> */}
        </div>
        </div>
    )
}