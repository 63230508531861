export default function Instructions({returnToSim, simCase, simPart}) {
    return (
        <div className="instructions-one-ctr sim-modal">
            <h2>Instructions</h2>
            <div>
                <h4>Welcome to the Hyponatremia Simulator! As a healthcare professional, your role is to evaluate and manage a virtual patient with hyponatremia. Follow these steps:</h4>
                <br />
                <br />
                <ol>
                    <li><strong>Triage Note Review:</strong> Read the patient's summary for context.</li>
                    <li><strong>Patient Interaction:</strong> Use the input box to ask questions and gather information.</li>
                    <li><strong>Access Additional Information:</strong> Check medical records, lab results, and physical exam findings.</li>
                    <li><strong>Initial Impression:</strong> Formulate your preliminary diagnosis based on collected data.</li>
                    <li><strong>Diagnostic Interventions:</strong> List necessary tests/imaging to confirm your diagnosis.</li>
                    <li><strong>Therapeutic Interventions:</strong> Compile recommended treatments.</li>
                    <li><strong>Feedback and Explanation:</strong> Get feedback and explanations for your decisions.</li>
                </ol>
            </div>
            <button className="return" onClick={returnToSim}>{simPart === 2 ? <h3>Return to Patient</h3> : <h3>Return to Feedback</h3>}</button>
        </div>
    )
}